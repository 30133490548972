import React, { useEffect, useState } from "react";
import { Modal, Box, HStack,VStack, Button, Radio } from "native-base";

export default function ModalEvolucao({id, nome, visivel, setModalVisibleEvolucao, navigation}) {
    const [valueAvCompleta, setValueAvCompleta] = useState('');
    const [valueRevisitas, setValueRevisitas] = useState('');
    const [valueEvolucao, setValueEvolucao] = useState('');
    
    const handleRevisita = (valor) => {
        setValueRevisitas(valor);
        setModalVisibleEvolucao(false); 
        limparCampos();
        valor == 2 ? navigation.navigate('RevisitaComRisco') : '';
        valor == 3 ? navigation.navigate('RevisitaSemRisco') : '';
        valor == 4 ? navigation.navigate('RevisitaLactente') : '';
        valor == 5 ? navigation.navigate('RevisitaPuerpera') : '';
        
    };
    
    const handleAvaliacao = (valor) => {
        setValueAvCompleta(valor);
        setModalVisibleEvolucao(false);
        limparCampos();
        valor == 1 ? navigation.navigate('AvaliacaoCompleta') : '';
    }

    const limparCampos = () => {
        setValueAvCompleta('');
        setValueRevisitas('');
        setValueEvolucao('');
    }
   

    
   
    return(<Modal isOpen={visivel}  size="lg" alignItems="center">
    <Modal.Content>
        <Modal.CloseButton onPress={() => {setModalVisibleEvolucao(!visivel)}} />
        <Modal.Header>Evoluções {nome}</Modal.Header>
        <Modal.Body>
            <Box alignItems="center">
                <HStack space={2} w="100%">
                    <Button.Group isAttached colorScheme="primary">
                        <Button size="lg" w="90%" variant={valueEvolucao == 1 ? "solid" : "outline"} onPress={() => setValueEvolucao(1)}>Avaliação Comp.</Button>
                        <Button size="lg" w="90%" variant={valueEvolucao == 2 ? "solid" : "outline"} onPress={() => setValueEvolucao(2)}>Revisitas</Button>
                    </Button.Group>
                </HStack>
                { valueEvolucao == 1 &&
                    <VStack space={5} py={5}>
                        <Radio.Group name="av_completa" accessibilityLabel="Avaliação Completa" value={valueAvCompleta} onChange={nextValue => {handleAvaliacao(nextValue)}} space={3}>
                            <Radio value="1">Com risco e / ou com TN</Radio>
                            <Radio value="2">Gestante</Radio>
                        </Radio.Group>
                    </VStack>
                }
                { valueEvolucao == 2 &&
                    <VStack space={5} py={5}>
                        <Radio.Group name="revisitas" accessibilityLabel="Revisitas" value={valueRevisitas} onChange={nextValue => {
                                                                handleRevisita(nextValue);
                                                            }} space={3}>
                            <Radio value="1">Primeira Evolução SRN</Radio>
                            <Radio value="2">Com risco e/ou TN</Radio>
                            <Radio value="3">Sem risco e s/ TN</Radio>
                            <Radio value="4">Lactente</Radio>
                            <Radio value="5">Puérpera</Radio>
                        </Radio.Group>
                    </VStack>
                }

                
            </Box>
        </Modal.Body>
        <Modal.Footer>
            <Button.Group variant="ghost" space={2}>
                <Button onPress={() => {
                    setModalVisibleEvolucao(!visivel);
                }} colorScheme="secondary">
                    Fechar
                </Button>
            </Button.Group>
        </Modal.Footer>
    </Modal.Content>
</Modal>);
}