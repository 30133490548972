import React,{ useEffect, useState } from "react";
import { Center, Heading, Text, VStack,Badge, FormControl, Box, Input, Link, Button, HStack, Image, StatusBar, Container, IconButton, Icon, View, FlatList, Flex, Spacer, Divider, Select, CheckIcon, ScrollView, Alert, CloseIcon, useToast, Spinner, Radio, Checkbox, Modal } from "native-base";
import {MaterialIcons, Ionicons, FontAwesome5} from "@expo/vector-icons";
import ModalEvolucao from "../modal/ModalEvolucao";



export default function Evolucao ({navigation})
{  
    const [isLoading, setLoading] = useState(false);
    const [idPaciente, setIdPaciente] = useState('');
    const [nomePaciente, setNomePaciente] = useState('');
    const [paciente, setPaciente] = useState([{
        id: 1,
        nome: 'Paciente de teste 01',
        data_nasc: '01/01/1976',
        atendimento: '123456',
        leito: 'UTI C1',
        evolucao: 1,
        cartao: 0
    },
    {
        id: 2,
        nome: 'Paciente de teste 02',
        data_nasc: '01/01/1992',
        atendimento: '123456',
        leito: 'UTI C2',
        evolucao: 0,
        cartao: 1   
    },
    {
        id: 3,
        nome: 'Paciente de teste 03',
        data_nasc: '01/01/1980',
        atendimento: '123456',
        leito: 'UTI C3',
        evolucao: 0,
        cartao: 0   
    },
    {
        id: 4,
        nome: 'Paciente de teste 04',
        data_nasc: '01/01/1996',
        atendimento: '123456',
        leito: 'UTI C4',
        evolucao: 1,
        cartao: 0   
    },
    {
        id: 5,
        nome: 'Paciente de teste 05',
        data_nasc: '01/01/1952',
        atendimento: '123456',
        leito: 'APTO 101'  ,
        evolucao: 1,
        cartao: 1 
    }]);
    const [modalVisibleEvolucao, setModalVisibleEvolucao] = useState(false);

    const setModalEv = () => {
        setModalVisibleEvolucao(!modalVisibleEvolucao);
    }

    const handleEvolucao = (id, nome) => {
        setModalVisibleEvolucao(true);
        setIdPaciente(id);
        setNomePaciente(nome);
    }
    return (
        <View bgColor="warmGray.100" flex={1}>
            <StatusBar barStyle="dark-content" />
            <Box safeAreaTop bgColor="warmGray.100" px={5} py={2}>
                <HStack space={1} alignItems="flex-start" p="2" w="70%">
                    <Input value="Nome: Técnica em Nutrição" size="lg" isReadOnly/>
                </HStack>
            </Box>
            <Box px={5}>
                <HStack p={2} space={3}>
                    <Button variant="solid" bgColor="blueGray.800" endIcon={<Badge colorScheme="danger" rounded="full" zIndex={1} variant="solid" alignSelf="flex-end" _text={{ fontSize: 10 }}>2</Badge>}>
                        Nutricionista 1
                    </Button>
                    <Button variant="solid" bgColor="blueGray.800" endIcon={<Badge colorScheme="danger" rounded="full" zIndex={1} variant="solid" alignSelf="flex-end" _text={{ fontSize: 10 }}>1</Badge>}>
                        Nutricionista 2
                    </Button>
                    <Button variant="solid" bgColor="blueGray.800" endIcon={<Badge colorScheme="danger" rounded="full" zIndex={1} variant="solid" alignSelf="flex-end" _text={{ fontSize: 10 }}>3</Badge>}>
                        Nutricionista 3
                    </Button>
                    <Button variant="solid" bgColor="blueGray.800" endIcon={<Badge colorScheme="success" rounded="full" zIndex={1} variant="solid" alignSelf="flex-end" _text={{ fontSize: 10 }}>0</Badge>}>
                        Nutricionista 4
                    </Button>
                    <Button variant="solid" bgColor="blueGray.800" endIcon={<Badge colorScheme="danger" rounded="full" zIndex={1} variant="solid" alignSelf="flex-end" _text={{ fontSize: 10 }}>1</Badge>}>
                        Nutricionista 5
                    </Button>
                </HStack>
            </Box>
            <View flex={1} py={5} borderRadius={50}>
                    <FlatList data={paciente} renderItem = {({item}) => 
                        <HStack>
                            <VStack w="60%">
                                <HStack bg="gray.200" space={3} borderBottomWidth={0.5} borderBottomColor="gray.300">
                                    <Box w="50%" py={2}>
                                        <Text adjustsFontSizeToFit px={5}>{item.nome}</Text>
                                    </Box>
                                    <Box w="15%" py={2}>
                                        <Text adjustsFontSizeToFit>{item.data_nasc}</Text>
                                    </Box>
                                    <Box w="15%" py={2}>
                                        <Text adjustsFontSizeToFit>{item.atendimento}</Text>
                                    </Box>
                                    <Box w="15%" py={2}>
                                        <Text adjustsFontSizeToFit>{item.leito}</Text>
                                    </Box>
                                </HStack>
                            </VStack>
                            <HStack w="40%">
                                <Box py={2} w="33%" bgColor="gray.200" borderRightColor="gray.300" borderRightWidth={0.5} borderBottomWidth={0.5} borderBottomColor="gray.300" alignItems="center">
                                    <HStack flexDirection="row" space={20} >
                                        <HStack justifyContent="flex-start">
                                            <Icon as={FontAwesome5} name="plus-circle" size="sm" color="blueGray.800" onPress={() => {handleEvolucao(item.id, item.nome)}} />
                                        </HStack>
                                        <HStack justifyContent="flex-end">
                                            <Icon as={FontAwesome5} name="check-circle" size="sm" color="success.800" />
                                        </HStack>
                                    </HStack>
                                </Box>
                                <Box py={2} w="33%" alignItems="center" bgColor="gray.200" borderRightColor="gray.300" borderRightWidth={0.5} borderBottomWidth={0.5} borderBottomColor="gray.300">
                                    <HStack space={10}>
                                        <Icon as={FontAwesome5} name="plus-circle" size="sm" color="blueGray.800" />
                                        { item.cartao ? <Icon as={FontAwesome5} name="file-alt" size="sm"/> : <></> }
                                        <Icon as={FontAwesome5} name="check-circle" size="sm" color="success.800" />
                                    </HStack>
                                    
                                </Box>
                                <Box py={2} w="33%" alignItems="center" bgColor="gray.200" borderBottomWidth={0.5} borderBottomColor="gray.300">
                                    <HStack space={5}>
                                        <Icon as={FontAwesome5} name="plus-circle" size="sm" color="blueGray.800" />
                                        <Badge colorScheme="blueGray.800" zIndex={1} variant="solid" alignSelf="flex-end" _text={{ fontSize: 10 }}>DM</Badge>
                                        <Icon as={FontAwesome5} name="check-circle" size="sm" color="success.800" />
                                    </HStack>
                                </Box>
                            </HStack>
                        </HStack>
                        
                    } keyExtractor={item => item.id.toString()} ListHeaderComponent={
                                <HStack bg="blueGray.800" space={3}>
                                    <VStack w="60%">
                                        <HStack>
                                            <Box w="100%" py={2} alignContent="center">
                                                <Text px={5} fontWeight="bold" color="white" alignSelf="center">PACIENTES</Text>
                                            </Box>
                                        </HStack>
                                        <HStack bgColor="gray.300">
                                            <Box w="50%" py={2} px={5}>
                                                <Text fontWeight="bold">Nome</Text>
                                            </Box>
                                            <Box w="15%" py={2} >
                                                <Text fontWeight="bold" alignSelf="center">Data Nasc.</Text>
                                            </Box>
                                            <Box w="15%" py={2} >
                                                <Text fontWeight="bold" alignSelf="center">Atendimento</Text>
                                            </Box>
                                            <Box w="15%" py={2} >
                                                <Text fontWeight="bold" alignSelf="center">Leito</Text>
                                            </Box>
                                        </HStack>
                                    </VStack>
                                    <HStack w="40%" space={10} px={5} py={5}>
                                        <Box>
                                            <Text px={5} fontWeight="bold" color="white" alignSelf="center">EVOLUÇÕES</Text>
                                        </Box>
                                        <Box>
                                            <Text px={5} fontWeight="bold" color="white" alignSelf="center">CARTÕES</Text>
                                        </Box>
                                        <Box>
                                            <Text px={5} fontWeight="bold" color="white" alignSelf="center">ORIENTAÇÕES</Text>
                                        </Box>
                                    </HStack>
                                    
                                </HStack>
                        } ListEmptyComponent={
                            <HStack bg="gray.100" space={3}>
                                <Box w="100%">
                                    <Text textAlign="center" color="red.600" py={5} fontSize={16} fontWeight="bold">Não existem pacientes cadastrados</Text>
                                </Box>
                            </HStack>
                        } />
                </View>
                <ModalEvolucao id={idPaciente} nome={nomePaciente} visivel={modalVisibleEvolucao} setModalVisibleEvolucao={setModalEv} navigation={navigation} />
        </View>
    );

}