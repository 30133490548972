
    const indicadores = [
        {
            "id" : 1,
            "item" : 0,
            "texto" : "ADMISSÃO"
        },
        {
            "id" : 2,
            "item" : 1,
            "texto" : "TRIAGENS REALIZADAS"
        },
        {
            "id" : 3,
            "item" : 2,
            "texto" : "Triagens com risco nutricional"
        },
        {
            "id" : 4,
            "item" : 2,
            "texto" : "Triagens sem risco nutricional"
        },
        {
            "id" : 5,
            "item" : 1,
            "texto" : "Avaliação completa (gestantes)"
        },
        {
            "id" : 6,
            "item" : 1,
            "texto" : "Avaliação completa pacientes sem risco que irão iniciar TN"
        },
        {
            "id" : 7,
            "item" : 1,
            "texto" : "Avaliação completa (pacientes com risco nutricional)"
        },
        {
            "id" : 8,
            "item" : 1,
            "texto" : "Nº de pacientes que iniciaram TN na admissão"
        },
        {
            "id" : 9,
            "item" : 0,
            "texto" : "INTERNAÇÃO"
        },
        {
            "id" : 10,
            "item" : 1,
            "texto" : "Nº de pacientes visitados (admitidos)"
        },
        {
            "id" : 11,
            "item" : 1,
            "texto" : "Nº de pacientes revisitados"
        },
        {
            "id" : 12,
            "item" : 1,
            "texto" : "Avaliação completa pacientes sem risco que irão iniciar TN "
        },
        {
            "id" : 13,
            "item" : 1,
            "texto" : "Avaliação completa (pacientes da EMTN em dieta zero)"
        },
        {
            "id" : 14,
            "item" : 1,
            "texto" : "Nº de evoluções realizadas (sem TN ou dieta zero)"
        },
        {
            "id" : 15,
            "item" : 1,
            "texto" : "Nº de evoluções realizadas (com TN)"
        },
        {
            "id" : 16,
            "item" : 1,
            "texto" : "Nº de reavaliações (AV. Completa) em gestantes sem TN"
        },
        {
            "id" : 17,
            "item" : 1,
            "texto" : "Nº de reavaliações (AV. Completa) em gestantes com TN"
        },
        {
            "id" : 18,
            "item" : 1,
            "texto" : "Nº de retriagens sem TN"
        },
        {
            "id" : 19,
            "item" : 1,
            "texto" : "Nº de retriagens com TN"
        },
        {
            "id" : 20,
            "item" : 1,
            "texto" : "Nº pacientes identificados com risco pela retriagem"
        },
        {
            "id" : 21,
            "item" : 1,
            "texto" : "Avaliação completa pacientes identificados com risco nutricional na retriagem"
        },
        {
            "id" : 22,
            "item" : 1,
            "texto" : "Avaliação completa pacientes sem risco (identificados pela retriagem) que mantem uso de TN"
        },
        {
            "id" : 23,
            "item" : 0,
            "texto" : "DIAGNÓSTICO NUTRICIONAL DOS PACIENTES EM TN (PREENCHER APENAS NA PRIMEIRA AVALIAÇÃO COMPLETA)"
        },
        {
            "id" : 24,
            "item" : 1,
            "texto" : "Desnutrição Não grave relacionada a inanição ou não relacionada à doença"
        },
        {
            "id" : 25,
            "item" : 1,
            "texto" : "Desnutrição Não grave relacionada à doença aguda"
        },
        {
            "id" : 26,
            "item" : 1,
            "texto" : "Desnutrição Não grave  relacionada à doença crônica "
        },
        {
            "id" : 27,
            "item" : 1,
            "texto" : "Desnutrição Não grave relacionada à doença crônica agudizada"
        },
        {
            "id" : 28,
            "item" : 1,
            "texto" : "Desnutrição Grave relacionada a inanição ou não relacionada a doença "
        },
        {
            "id" : 29,
            "item" : 1,
            "texto" : "Desnutrição Grave  relacionada à doença aguda "
        },
        {
            "id" : 30,
            "item" : 1,
            "texto" : "Desnutrição Grave  relacionada à doença crônica"
        },
        {
            "id" : 31,
            "item" : 1,
            "texto" : "Desnutrição Grave  relacionada à doença crônica agudizada"
        },
        {
            "id" : 32,
            "item" : 1,
            "texto" : "Alto risco para desenvolvimento de desnutrição relacionada à (etiologia da desnutrição) "
        },
        {
            "id" : 33,
            "item" : 1,
            "texto" : "Baixo peso"
        },
        {
            "id" : 34,
            "item" : 1,
            "texto" : "Eutrofia"
        },
        {
            "id" : 35,
            "item" : 1,
            "texto" : "Sobrepeso\/Obesidade\/Excesso de peso"
        },
        {
            "id" : 36,
            "item" : 0,
            "texto" : "Diagnóstico Nutricional das crianças avaliadas (0 a 28 dias de vida) (P\/IG) - preencher apenas na primeira avaliação"
        },
        {
            "id" : 37,
            "item" : 1,
            "texto" : "PIG"
        },
        {
            "id" : 38,
            "item" : 1,
            "texto" : "AIG"
        },
        {
            "id" : 39,
            "item" : 1,
            "texto" : "GIG"
        },
        {
            "id" : 40,
            "item" : 0,
            "texto" : "Diagnóstico Nutricional das crianças avaliadas na Pediatria (P\/E < 2 anos e IMC\/I > 2 anos) - preencher apenas na primeira avaliação dos pacientes com Risco Nutricional"
        },
        {
            "id" : 41,
            "item" : 1,
            "texto" : "Magreza acentuada"
        },
        {
            "id" : 42,
            "item" : 1,
            "texto" : "Magreza"
        },
        {
            "id" : 43,
            "item" : 1,
            "texto" : "Eutrofia"
        },
        {
            "id" : 44,
            "item" : 1,
            "texto" : "Risco de Sobrepeso"
        },
        {
            "id" : 45,
            "item" : 1,
            "texto" : "Sobrepeso"
        },
        {
            "id" : 46,
            "item" : 1,
            "texto" : "Obesidade"
        },
        {
            "id" : 47,
            "item" : 1,
            "texto" : "Obesidade grave"
        },
        {
            "id" : 48,
            "item" : 0,
            "texto" : "TERAPIA NUTRICIONAL (INCIDÊNCIA)"
        },
        {
            "id" : 49,
            "item" : 1,
            "texto" : "Nº de novos de pacientes em TN (total)"
        },
        {
            "id" : 50,
            "item" : 1,
            "texto" : "Nº de novos pacientes em TN que possuem avaliação completa (feita hoje ou anteriormente)"
        },
        {
            "id" : 51,
            "item" : 0,
            "texto" : "TERAPIA NUTRICIONAL (PREVALÊNCIA)"
        },
        {
            "id" : 52,
            "item" : 1,
            "texto" : "Nº de pacientes em TN (Total)"
        },
        {
            "id" : 53,
            "item" : 2,
            "texto" : "Nº de pacientes com sonda"
        },
        {
            "id" : 54,
            "item" : 2,
            "texto" : "Nº de pacientes com suplemento"
        },
        {
            "id" : 55,
            "item" : 2,
            "texto" : "Nº de pacientes em nutrição parenteral"
        },
        {
            "id" : 56,
            "item" : 2,
            "texto" : "Nº de pacientes com duas ou mais vias de TN"
        },
        {
            "id" : 57,
            "item" : 0,
            "texto" : "PACIENTE EM USO DE SONDA EXCLUSIVA (ADULTOS)"
        },
        {
            "id" : 58,
            "item" : 1,
            "texto" : "Nº de pacientes com sonda inicada em menos de  72 horas "
        },
        {
            "id" : 59,
            "item" : 1,
            "texto" : "Nº de pacientes com sonda atingindo meta nutricional (calórica) - pacientes com mais de 72h de TNE"
        },
        {
            "id" : 60,
            "item" : 1,
            "texto" : "Nº de pacientes com sonda fora da meta nutricional (calórica) - pacientes com mais de 72h de TNE"
        },
        {
            "id" : 61,
            "item" : 1,
            "texto" : "MOTIVOS DA NÃO ADEQUAÇÃO CALÓRICA"
        },
        {
            "id" : 62,
            "item" : 2,
            "texto" : "Diarréia"
        },
        {
            "id" : 63,
            "item" : 2,
            "texto" : "Procedimentos"
        },
        {
            "id" : 64,
            "item" : 2,
            "texto" : "Instabilidade hemodinâmica"
        },
        {
            "id" : 65,
            "item" : 2,
            "texto" : "Vômitos\/ distensão abdominal"
        },
        {
            "id" : 66,
            "item" : 2,
            "texto" : "Saida inadvertida\/obstrução de sonda"
        },
        {
            "id" : 67,
            "item" : 2,
            "texto" : "Erro de infusão"
        },
        {
            "id" : 68,
            "item" : 2,
            "texto" : "Outros"
        },
        {
            "id" : 69,
            "item" : 1,
            "texto" : "Nº de pacientes com sonda atingindo meta nutricional (proteica) - pacientes com mais de 72h de TNE"
        },
        {
            "id" : 70,
            "item" : 1,
            "texto" : "Nº de pacientes com sonda fora da meta nutricional (proteica) - pacientes com mais de 72h de TNE"
        },
        {
            "id" : 71,
            "item" : 1,
            "texto" : "MOTIVOS DA NÃO ADEQUAÇÃO PROTEICA"
        },
        {
            "id" : 72,
            "item" : 2,
            "texto" : "Diarréia"
        },
        {
            "id" : 73,
            "item" : 2,
            "texto" : "Procedimentos"
        },
        {
            "id" : 74,
            "item" : 2,
            "texto" : "Instabilidade hemodinâmica"
        },
        {
            "id" : 75,
            "item" : 2,
            "texto" : "Vômitos\/ distensão abdominal"
        },
        {
            "id" : 76,
            "item" : 2,
            "texto" : "Saida inadvertida\/obstrução de sonda"
        },
        {
            "id" : 77,
            "item" : 2,
            "texto" : "Erro de infusão"
        },
        {
            "id" : 78,
            "item" : 2,
            "texto" : "Outros"
        },
        {
            "id" : 79,
            "item" : 0,
            "texto" : "OUTRAS ATIVIDADES"
        },
        {
            "id" : 80,
            "item" : 1,
            "texto" : "Pareceres respondidos"
        },
        {
            "id" : 81,
            "item" : 1,
            "texto" : "ORIENTAÇÃO DE ALTA HOSPITALAR"
        },
        {
            "id" : 82,
            "item" : 2,
            "texto" : "Orientação de alta hospitalar (com ou sem suplemento)"
        },
        {
            "id" : 83,
            "item" : 2,
            "texto" : "Orientação de alta hospitalar (sonda ou parenteral que receberam alta)"
        },
        {
            "id" : 84,
            "item" : 1,
            "texto" : "Nº de pacientes em uso de sonda ou parenteral que receberam alta"
        },
        {
            "id" : 85,
            "item" : 1,
            "texto" : "Nº de bioimpedâncias realizadas"
        },
        {
            "id" : 86,
            "item" : 1,
            "texto" : "Nº de exames de calorimetria realizados"
        }
    ];
export default indicadores;
    