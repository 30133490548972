import React,{ useEffect, useState } from "react";
import { Center, Heading, Text, VStack,Badge, FormControl, Box, Input, Link, Button, HStack, Image, StatusBar, Container, IconButton, Icon, View, FlatList, Flex, Spacer, Divider, Select, CheckIcon, ScrollView, Alert, CloseIcon, useToast, Spinner, Radio, Checkbox, Modal, TextArea } from "native-base";
import {MaterialIcons, Ionicons, FontAwesome5} from "@expo/vector-icons";



export default function RevisitaComRisco ({navigation})
{  
    const [isLoading, setLoading] = useState(false);
    const [idPaciente, setIdPaciente] = useState('');
    const [nomePaciente, setNomePaciente] = useState('');
    const [aceitacao, setAceitacao] = useState('');
    const [diurese, setDiurese] = useState('');
    const [evacuacao, setEvacuacao] = useState('');
    const [trato, setTrato] = useState('');
    const [alteracoes, setAlteracoes] = useState([]);
    const [amamentacao, setAmamentacao] = useState('');
    const [intercorrencias, setIntercorrencias] = useState([]);
    const [conduta, setConduta] = useState([]);
    const [prescricao, setPrescricao] = useState('');
    const [dias, setDias] = useState('');
    const [episodios, setEpisodios] = useState('');
    const [vomitos, setVomitos] = useState('');
    const [outros, setOutros] = useState('');
    const [outrasintercorrencias, setOutrasintercorrencias] = useState('');
    const [outraconduta, setOutraconduta] = useState('');


    const [modalVisibleEvolucao, setModalVisibleEvolucao] = useState(false);

    const setModalEv = () => {
        setModalVisibleEvolucao(!modalVisibleEvolucao);
    }

    const handleEvolucao = (id, nome) => {
        setModalVisibleEvolucao(true);
        setIdPaciente(id);
        setNomePaciente(nome);
    }

    const handlePrescricao = e => {
        setPrescricao(e.currentTarget.value);
    }
    return (
        <View bgColor="warmGray.100">
            <StatusBar barStyle="dark-content" />
            <Box safeAreaTop bgColor="blueGray.900" px={5} py={2}>
                <HStack space={1} p="2" w="100%">
                    <HStack space={5}>
                        <Input value="Samanta de Morais Gomes Luz" size="lg" isReadOnly color="white"/>
                        <Input value="18/04/1992" size="lg" isReadOnly color="white" />
                        <Input value="Atendimento: 1594445" size="lg" isReadOnly color="white" />
                    </HStack>
                    <HStack justifyContent="flex-end">
                        <Heading color="white">Revisita Com Risco e TN</Heading>
                        <IconButton icon={<Icon as={MaterialIcons} name="arrow-back" size="md" color="white" />} onPress={() => navigation.goBack()} />
                    </HStack>
                </HStack>
            </Box>
            <Box safeAreaTop bgColor="blueGray.900" alignItems="flex-end">
                <HStack space={2} p="3">
                    {isLoading && <Box><Spinner accessibilityLabel="Carregando" /><Text color="white">Carregando...</Text></Box>}
                </HStack>
            </Box>
            <FormControl>

                <HStack justifyContent="center" my={5} space={5} direction="row">
                    <Box bg="gray.50" rounded="lg" borderColor="coolGray.200" borderWidth="1" p={10} w="30%" shadow={2} minH={300}>
                        <FormControl.Label fontSize={22} fontWeight="bold">Aceitação da dieta</FormControl.Label>
                        <Radio.Group name="aceitacao" accessibilityLabel="Aceitação da dieta" value={aceitacao} onChange={nextValue => {setAceitacao(nextValue)}} space={3}>
                            <Radio value="1">Boa (&gt;= 70%)</Radio>
                            <Radio value="2">Regular (&gt;= 50% e &lt;70%)</Radio>
                            <Radio value="3">Ruim (&gt;= 20% e &lt;50%)</Radio>
                            <Radio value="4">Quase nula (&lt;20%)</Radio>
                            <Radio value="5">Não se aplica (Dieta zero / jejum)</Radio>
                        </Radio.Group>
                    </Box>
                    <Box bg="gray.50" rounded="lg" borderColor="coolGray.200" borderWidth="1" p={10} w="30%" shadow={2} minH={300}>
                        <FormControl.Label>Eliminações fisiológicas:</FormControl.Label>
                        <FormControl.Label>Diurese</FormControl.Label>
                        <Radio.Group name="diurese" accessibilityLabel="Diurese" value={diurese} onChange={nextValue => {setDiurese(nextValue)}} space={3}>
                            <Radio value="1">Preservada</Radio>
                            <Radio value="2">Alterações</Radio>
                        </Radio.Group>
                        <FormControl.Label>Evacuação</FormControl.Label>
                        <Radio.Group name="evacuacao" accessibilityLabel="Evacuação" value={evacuacao} onChange={nextValue => {setEvacuacao(nextValue)}} space={3}>
                            <Radio value="1">Sem alterações</Radio>
                            <Radio value="2">Ausente</Radio>
                            <Radio value="3">Diarreia</Radio>
                        </Radio.Group>
                        {
                            evacuacao == 2 &&
                            <Box>
                                <FormControl.Label>Há quantos dias?</FormControl.Label>
                                <Input value={dias} name="dias" />
                            </Box>
                        }
                        {
                            evacuacao == 3 &&
                            <Box>
                                <FormControl.Label>Quantos episódios por dia</FormControl.Label>
                                <Input value={episodios} name="episodios" />
                            </Box>
                        }
                    </Box>
                    <Box bg="gray.50" rounded="lg" borderColor="coolGray.200" borderWidth="1" p={10} w="30%" shadow={2} minH={300}>
                        <FormControl.Label>Trato gastrointestinal</FormControl.Label>
                        <Radio.Group name="trato" accessibilityLabel="Trato gastrointestinal" value={trato} onChange={nextValue => {setTrato(nextValue)}} space={3}>
                            <Radio value="1">Sem alterações</Radio>
                            <Radio value="2">Com alterações</Radio>
                        </Radio.Group>
                        {
                            trato == 2 &&
                            <Checkbox.Group name="alteracoes" accessibilityLabel="Com alterações" value={alteracoes} onChange={nextValue => {setAlteracoes(nextValue)}} space={3} pt={3} pl={10}>
                                <Checkbox value="1">Vômitos</Checkbox>
                                <Checkbox value="2">Náuseas</Checkbox>
                                <Checkbox value="3">Distensão abdominal</Checkbox>
                                <Checkbox value="4">Outros</Checkbox>
                            </Checkbox.Group>
                        }
                        {
                            alteracoes.includes("1") && 
                            <Box>
                                <FormControl.Label>Episódios</FormControl.Label>
                                <Input value={vomitos} name="vomitos" />
                            </Box>
                        }
                        {
                            alteracoes.includes("4") && 
                            <Box>
                                <FormControl.Label>Outros</FormControl.Label>
                                <Input value={outros} name="outros" />
                            </Box>
                        }
                       
                    </Box>
                </HStack>
                <HStack justifyContent="center" space={5} my={5}>
                    

                    <Box bg="gray.50" rounded="lg" borderColor="coolGray.200" borderWidth="1" p={10} w="30%" shadow={2} minH={300}>
                        <FormControl.Label fontSize={18} fontWeight="bold">Prescrição dietética</FormControl.Label>
                        <TextArea value={prescricao} onChange={handlePrescricao}></TextArea>
                    </Box>

                    <Box bg="gray.50" rounded="lg" borderColor="coolGray.200" borderWidth="1" p={10} w="30%" shadow={2} minH={300}>
                        <FormControl.Label fontSize={18} fontWeight="bold">Conduta Nutricional</FormControl.Label>
                        <Checkbox.Group name="conduta" accessibilityLabel="Conduta Nutricional" value={conduta} onChange={nextValue => {setConduta(nextValue)}} space={3}>
                            <Checkbox value="1">Realizo ajustes de acordo com preferências alimentares</Checkbox>
                            <Checkbox value="2">Monitoro aceitação dietética, sintomas gastrintestinais e estado nutricional</Checkbox>
                            <Checkbox value="3">Acompanho exames bioquímicos e evolução clínica</Checkbox>
                            <Checkbox value="4">Reavalio em 7 dias</Checkbox>
                            <Checkbox value="5">Segue em acompanhamento nutricional diário para melhor monitorização do quadro clínico</Checkbox>
                            <Checkbox value="6">Discuto caso com EMTN</Checkbox>
                            <Checkbox value="7">Outra</Checkbox>
                        </Checkbox.Group>
                        {
                            conduta.includes("7") &&
                            <Box>
                                <FormControl.Label>Outra</FormControl.Label>
                                <Input value={outraconduta} name="outraconduta" />
                            </Box>
                        }
                    </Box>


                </HStack>
            </FormControl>
        </View>
    );

}