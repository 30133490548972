import React,{ useEffect, useState } from "react";
import { Center, Heading, Text, VStack,Badge, FormControl, Box, Input, Link, Button, HStack, Image, StatusBar, Container, IconButton, Icon, View, FlatList, Flex, Spacer, Divider, Select, CheckIcon, ScrollView, Alert, CloseIcon, useToast, Spinner, Radio, Checkbox, Modal, TextArea } from "native-base";
import {MaterialIcons, Ionicons, FontAwesome5} from "@expo/vector-icons";



export default function AvaliacaoCompleta ({navigation})
{  
    const [isLoading, setLoading] = useState(false);
    const [idPaciente, setIdPaciente] = useState('');
    const [nomePaciente, setNomePaciente] = useState('');
   
    const [diagnostico, setDiagnostico] = useState('');
    const [alergia, setAlergia] = useState('');
    


    const [modalVisibleEvolucao, setModalVisibleEvolucao] = useState(false);

    const setModalEv = () => {
        setModalVisibleEvolucao(!modalVisibleEvolucao);
    }

    const handleEvolucao = (id, nome) => {
        setModalVisibleEvolucao(true);
        setIdPaciente(id);
        setNomePaciente(nome);
    }

    const handleDiagnostico = e => {
        setDiagnostico(e.currentTarget.value);
    }
    return (
        <View bgColor="warmGray.100">
            <StatusBar barStyle="dark-content" />
            <Box safeAreaTop bgColor="blueGray.900" px={5} py={2}>
                <HStack space={1} p="2" w="100%">
                    <HStack space={5}>
                        <Input value="Samanta de Morais Gomes Luz" size="lg" isReadOnly color="white"/>
                        <Input value="18/04/1992" size="lg" isReadOnly color="white" />
                        <Input value="Atendimento: 1594445" size="lg" isReadOnly color="white" />
                    </HStack>
                    <HStack justifyContent="flex-end">
                        <Heading color="white">Avaliação Completa</Heading>
                        <IconButton icon={<Icon as={MaterialIcons} name="arrow-back" size="md" color="white" />} onPress={() => navigation.goBack()} />
                    </HStack>
                </HStack>
            </Box>
            <Box safeAreaTop bgColor="blueGray.900" alignItems="flex-end">
                <HStack space={2} p="3">
                    {isLoading && <Box><Spinner accessibilityLabel="Carregando" /><Text color="white">Carregando...</Text></Box>}
                </HStack>
            </Box>
            <FormControl>
                <HStack direction="row" bg="gray.50" px={5}>
                    <Box w={"100%"} p={5}>
                        <FormControl.Label fontSize={18} fontWeight="bold">Diagnóstico</FormControl.Label>
                        <TextArea value={diagnostico} onChange={handleDiagnostico}></TextArea>
                    </Box>
                    
                </HStack>
                <HStack direction="row" bg="gray.50" px={5}>
                    <Box w={"50%"} p={5}>
                        <FormControl.Label fontSize={22} fontWeight="bold">Paciente admitido(a) pela nutrição clínica às: </FormControl.Label>
                        <Input name="hora_admissao" w={130} />
                        
                    </Box>
                    <Box w={"50%"} p={5}>
                        <FormControl.Label fontSize={22} fontWeight="bold">Refere Alergias ou intolerâncias alimentares?</FormControl.Label>
                        <Radio.Group name="alergia" accessibilityLabel="Refere Alergias ou intolerâncias alimentares?" value={alergia} onChange={nextValue => {setAlergia(nextValue)}} space={3}>
                            <Radio value="1">Não</Radio>
                            <Radio value="2">Sim</Radio>
                            <Radio value="3">Não sabe referir</Radio>
                        </Radio.Group>
                    </Box>
                </HStack>
                <HStack direction="row" bg="gray.50" px={5}>
                    <Box w={"50%"} p={5}>
                        <FormControl.Label fontSize={22} fontWeight="bold" pt={5}>Encontrava-se:</FormControl.Label>
                        <Checkbox.Group name="alteracoes" accessibilityLabel="Com alterações"  space={3} pt={3}>
                            <Checkbox value="1">Acordado</Checkbox>
                            <Checkbox value="2">Dormindo</Checkbox>
                            <Checkbox value="3">Sonolento</Checkbox>
                            <Checkbox value="4">Orientado</Checkbox>
                            <Checkbox value="5">Desorientado</Checkbox>
                            <Checkbox value="6">Lúcido</Checkbox>
                        </Checkbox.Group>
                    </Box>
                    <Box w={"50%"} p={5}>
                        <FormControl.Label fontSize={22} fontWeight="bold" pt={5}>Funcionamento intestinal </FormControl.Label>
                        <Input name="hora_admissao" />
                    </Box>
                </HStack>
                <HStack direction="row" bg="gray.50" px={5}>
                    <Box w={"50%"} p={5}>
                        <FormControl.Label fontSize={22} fontWeight="bold">Estava</FormControl.Label>
                        <Radio.Group name="alergia" accessibilityLabel="Refere Alergias ou intolerâncias alimentares?" value={alergia} onChange={nextValue => {setAlergia(nextValue)}} space={3}>
                            <Radio value="1">Com acompanhante</Radio>
                            <Radio value="2">Sem acompanhante</Radio>
                        </Radio.Group>
                    </Box>
                    <Box w={"50%"} p={5}>
                        <FormControl.Label fontSize={22} fontWeight="bold">Refere sintomas gastrointestinais? </FormControl.Label>
                        <Radio.Group name="alergia" accessibilityLabel="Refere sintomas gastrointestinais"space={3}>
                            <Radio value="1">Não</Radio>
                            <Radio value="2">Sim</Radio>
                            <Radio value="3">Não sabe referir</Radio>
                        </Radio.Group>
                    </Box>
                </HStack>

                <HStack direction="row" bg="gray.50" px={5}>
                    <Box w={"50%"} p={5}>
                        <FormControl.Label fontSize={22} fontWeight="bold">Informações referidas por</FormControl.Label>
                        <Radio.Group name="alergia" accessibilityLabel="Informações referidas por:" space={3}>
                            <Radio value="1">Acompanhante</Radio>
                            <Radio value="2">Paciente</Radio>
                        </Radio.Group>
                    </Box>
                    <Box w={"50%"} p={5}>
                        <FormControl.Label fontSize={22} fontWeight="bold">Apresenta dificuldade ou dor ao mastigar/engolir? </FormControl.Label>
                        <Radio.Group name="alergia" accessibilityLabel="Apresenta dificuldade ou dor ao mastigar/engolir?"space={3}>
                            <Radio value="1">Não</Radio>
                            <Radio value="2">Sim</Radio>
                            <Radio value="3">Não sabe referir</Radio>
                        </Radio.Group>
                    </Box>
                </HStack>

                <HStack direction="row" bg="gray.50" px={5}>
                    <Box w={"50%"} p={5}>
                        <FormControl.Label fontSize={22} fontWeight="bold">Refere comorbidades?</FormControl.Label>
                        <Radio.Group name="alergia" accessibilityLabel="Refere comorbidades?" space={3}>
                            <Radio value="1">Não</Radio>
                            <Radio value="2">Sim</Radio>
                            <Radio value="3">Não sabe referir</Radio>
                        </Radio.Group>
                    </Box>
                    <Box w={"50%"} p={5}>
                        <FormControl.Label fontSize={22} fontWeight="bold">Teve alteração do apetite previamente à internação hospitalar?</FormControl.Label>
                        <Radio.Group name="alergia" accessibilityLabel="Teve alteração do apetite previamente à internação hospitalar?"space={3}>
                            <Radio value="1">Não</Radio>
                            <Radio value="2">Sim</Radio>
                            <Radio value="3">Não sabe referir</Radio>
                        </Radio.Group>
                    </Box>
                </HStack>

                <HStack direction="row" bg="gray.50" px={5}>
                    <Box w={"50%"} p={5}>
                        <FormControl.Label fontSize={22} fontWeight="bold">Como está o apetite atualmente?</FormControl.Label>
                        <Radio.Group name="alergia" accessibilityLabel="Refere comorbidades?" space={3}>
                            <Radio value="1">Bom</Radio>
                            <Radio value="2">Irregular</Radio>
                            <Radio value="3">Ruim</Radio>
                        </Radio.Group>
                    </Box>
                    <Box w={"50%"} p={5}>
                        <FormControl.Label fontSize={22} fontWeight="bold">Houve perda ponderal recente?</FormControl.Label>
                        <Radio.Group name="alergia" accessibilityLabel="Houve perda ponderal recente?"space={3}>
                            <Radio value="1">Não</Radio>
                            <Radio value="2">Sim</Radio>
                            <Radio value="3">Não sabe referir</Radio>
                        </Radio.Group>
                    </Box>
                </HStack>

                <HStack direction="row" bg="gray.50" px={5}>
                    <Box w={"50%"} p={5}>
                        <FormControl.Label fontSize={22} fontWeight="bold">Faz uso de algum tipo de terapia nutricional previamente?</FormControl.Label>
                        <Radio.Group name="alergia" accessibilityLabel="Refere comorbidades?" space={3}>
                            <Radio value="1">Não</Radio>
                            <Radio value="2">Sim</Radio>
                            <Radio value="3">Não sabe referir</Radio>
                        </Radio.Group>
                    </Box>
                </HStack>
            </FormControl>
        </View>
    );

}