import React, {useState, useEffect} from "react";
import { Center, Heading, Text, VStack, FormControl, Box, Input, Link, Button, HStack, Select, Image } from "native-base";

import hospitais from "../data/hospitais";
import logo from "../assets/logo.png"
import AsyncStorageLib from "@react-native-async-storage/async-storage";

export default function Login ({navigation})
{
    const [hospital, setHospital] = useState('');
    const [usuario, setUsuario] = useState('');
    const [senha, setSenha] = useState('');


    
    const getData = async () => {
        try {
            const value = await AsyncStorageLib.getItem('@usuario');
            
            if(value !== null) {
                navigation.navigate('Home');
            }
        } catch(e) {
            // error reading value
        }
    }
    
    
    useEffect(() => {
        getData();
    }, []);

    const validaLogin = async () => {
        
        if(hospital == '')
        {
            alert('selecione o hospital');
            return false;
        }

        const dados = {
            token: 'login',
            id_hospital: hospital,
            ds_usuario: usuario,
            ds_senha: senha,
        }


        fetch('https://sisibranutro.com.br/appEstatistica/api.php', {
            method: 'POST',
            headers: {
              //Accept: 'application/json',
              //'Content-Type': 'multipart/form-data',
              'Content-Type' : 'application/json'
            },
            body: JSON.stringify(dados),
          })
            .then((response) => response.json())
            .then((json) => {
                console.log('entrou json');
                console.log(json.data.usuario.id_usuario);
                
                const storeData = async (value) => {
                    try {
                      await AsyncStorageLib.setItem('@usuario', JSON.stringify(json.data.usuario))
                    } catch (e) {
                      // saving error
                    }
                }
                storeData();
                    

                navigation.navigate('Home');
                

            })
            .catch((error) => console.log('caiu no erro:', error))
            .finally(() => console.log('passou'));
       
    }
    
    return (
        
        <Center bgColor="coolGray.200" h="100%">
            <Box  px="20" py="18" bgColor={"coolGray.100"} shadow={3}>
                <VStack space={3} mt="3">
                    <Image source={logo} alt="logo" w="400" h="150" resizeMode="contain" alignSelf="center"/>
                    <FormControl>
                        <FormControl.Label>Hospital</FormControl.Label>
                        <Select accessibilityLabel="Hospital" placeholder="Hospital" selectedValue={hospital} onValueChange={itemValue => setHospital(itemValue)}>
                            {
                                hospitais.map((option) => (
                                    <Select.Item label={option.ds_hospital} value={option.id_hospital.toString()} key={option.id_hospital.toString()} />
                                ))
                            }
                        </Select>
                        <FormControl.Label>Usuário</FormControl.Label>
                        <Input bgColor={"warmGray.100"} value={usuario} onChangeText={newValue => setUsuario(newValue)} />
                    </FormControl>
                    <FormControl>
                        <FormControl.Label>Senha</FormControl.Label>
                        <Input type="password" bgColor={"warmGray.100"} value={senha} onChangeText={newValue => setSenha(newValue)} />
                        <Link _text={{
                        fontSize: "xs",
                        fontWeight: "500",
                        color: "blueGray.400"
                        }} alignSelf="flex-end" mt="1">
                            Esqueceu a senha? 
                        </Link>
                    </FormControl>
                    <Button mt="2" onPress={() => {validaLogin()}}>
                        Entrar
                    </Button>
                    <HStack mt="6" justifyContent="center">
                        <Text fontSize="sm" color="blueGray.400" _dark={{
                            color: "white"
                            }}>
                                Não tenho usuário e senha. {" "}
                        </Text>
                        <Link _text={{
                            color: "blueGray.400",
                            fontWeight: "medium",
                            fontSize: "sm"
                            }} href="#">
                                Preciso de ajuda
                        </Link>
                    </HStack>
                </VStack>
            </Box>
        </Center>
        
    );
}