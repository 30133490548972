import React,{ useEffect, useState } from "react";
import { Center, Heading, Text, VStack,Badge, FormControl, Box, Input, Link, Button, HStack, Image, StatusBar, Container, IconButton, Icon, View, FlatList, Flex, Spacer, Divider, Select, CheckIcon, ScrollView, Alert, CloseIcon, useToast, Spinner, Radio, Checkbox, Modal, TextArea } from "native-base";
import {MaterialIcons, Ionicons, FontAwesome5} from "@expo/vector-icons";



export default function RevisitaLactente ({navigation})
{  
    const [isLoading, setLoading] = useState(false);
    const [idPaciente, setIdPaciente] = useState('');
    const [nomePaciente, setNomePaciente] = useState('');
    const [aceitacao, setAceitacao] = useState('');
    const [diurese, setDiurese] = useState('');
    const [evacuacao, setEvacuacao] = useState('');
    const [trato, setTrato] = useState('');
    const [alteracoes, setAlteracoes] = useState([]);
    const [amamentacao, setAmamentacao] = useState('');
    const [intercorrencias, setIntercorrencias] = useState([]);
    const [conduta, setConduta] = useState([]);
    const [prescricao, setPrescricao] = useState('');
    const [dias, setDias] = useState('');
    const [episodios, setEpisodios] = useState('');
    const [vomitos, setVomitos] = useState('');
    const [outros, setOutros] = useState('');
    const [outrasintercorrencias, setOutrasintercorrencias] = useState('');
    const [outraconduta, setOutraconduta] = useState('');


    const [modalVisibleEvolucao, setModalVisibleEvolucao] = useState(false);

    const setModalEv = () => {
        setModalVisibleEvolucao(!modalVisibleEvolucao);
    }

    const handleEvolucao = (id, nome) => {
        setModalVisibleEvolucao(true);
        setIdPaciente(id);
        setNomePaciente(nome);
    }

    const handlePrescricao = e => {
        setPrescricao(e.currentTarget.value);
    }
    return (
        <View bgColor="warmGray.100">
            <StatusBar barStyle="dark-content" />
            <Box safeAreaTop bgColor="blueGray.900" px={5} py={2}>
                <HStack space={1} p="2" w="100%">
                    <HStack space={5}>
                        <Input value="Samanta de Morais Gomes Luz" size="lg" isReadOnly color="white"/>
                        <Input value="18/04/1992" size="lg" isReadOnly color="white" />
                        <Input value="Atendimento: 1594445" size="lg" isReadOnly color="white" />
                    </HStack>
                    <HStack justifyContent="flex-end">
                        <Heading color="white">Revisita Lactente</Heading>
                        <IconButton icon={<Icon as={MaterialIcons} name="arrow-back" size="md" color="white" />} onPress={() => navigation.goBack()} />
                    </HStack>
                </HStack>
            </Box>
            <Box safeAreaTop bgColor="blueGray.900" alignItems="flex-end">
                <HStack space={2} p="3">
                    {isLoading && <Box><Spinner accessibilityLabel="Carregando" /><Text color="white">Carregando...</Text></Box>}
                </HStack>
            </Box>
            <FormControl>
                <HStack justifyContent="center" my={5} space={5} direction="row">
                    <Box bg="gray.50" rounded="lg" borderColor="coolGray.200" borderWidth="1" p={10} w="30%" shadow={2} minH={300}>
                        <FormControl.Label fontSize={22} fontWeight="bold">Aleitamento materno</FormControl.Label>
                        <Radio.Group name="aceitacao" accessibilityLabel="Aleitamento materno" space={3}>
                            <Radio value="1">Sim</Radio>
                            <Radio value="2">Não</Radio>
                        </Radio.Group>

                        <FormControl.Label fontSize={22} fontWeight="bold">Pega</FormControl.Label>
                        <Radio.Group name="aceitacao" accessibilityLabel="Aleitamento materno" space={3}>
                            <Radio value="1">Adequada</Radio>
                            <Radio value="2">Insuficiente</Radio>
                        </Radio.Group>

                        <FormControl.Label fontSize={22} fontWeight="bold">Sucção</FormControl.Label>
                        <Radio.Group name="aceitacao" accessibilityLabel="Aleitamento materno" space={3}>
                            <Radio value="1">Adequada</Radio>
                            <Radio value="2">Insuficiente</Radio>
                        </Radio.Group>
                    </Box>
                    <Box bg="gray.50" rounded="lg" borderColor="coolGray.200" borderWidth="1" p={10} w="30%" shadow={2} minH={300}>
                        <FormControl.Label>Sintomas gastrintestinais</FormControl.Label>
                        
                        <Radio.Group name="diurese" accessibilityLabel="Sintomas gastrintestinais" value={diurese} onChange={nextValue => {setDiurese(nextValue)}} space={3}>
                            <Radio value="1">Sem intercorrências</Radio>
                            <Radio value="2">Intercorrências. Quais?</Radio>
                        </Radio.Group>
                    </Box>
                    <Box bg="gray.50" rounded="lg" borderColor="coolGray.200" borderWidth="1" p={10} w="30%" shadow={2} minH={300}>
                        <FormControl.Label>Uso de fórmula / complemento?</FormControl.Label>
                        <Radio.Group name="diurese" accessibilityLabel="Uso de fórmula / complemento?" space={3}>
                            <Radio value="1">Sim</Radio>
                            <Radio value="2">Não</Radio>
                        </Radio.Group>

                        <FormControl.Label>Aceitação</FormControl.Label>
                        <Radio.Group name="diurese" accessibilityLabel="Aceitação"  space={3}>
                            <Radio value="1">Total</Radio>
                            <Radio value="2">Parcial</Radio>
                            <Radio value="3">Baixa / Nula</Radio>
                        </Radio.Group>
                    </Box>
                </HStack>
                <HStack justifyContent="center" my={5} space={5} direction="row">
                    <Box bg="gray.50" rounded="lg" borderColor="coolGray.200" borderWidth="1" p={10} w="30%" shadow={2} minH={300}>
                        <FormControl.Label>Evolução ponderal</FormControl.Label>
                        <TextArea></TextArea>
                    </Box>
                    <Box bg="gray.50" rounded="lg" borderColor="coolGray.200" borderWidth="1" p={10} w="30%" shadow={2} minH={300}>
                        <FormControl.Label>Funcionamento intestinal?</FormControl.Label>
                        <Radio.Group name="diurese" accessibilityLabel="Aceitação"  space={3}>
                            <Radio value="1">Sem alterações</Radio>
                            <Radio value="2">Diarreia</Radio>
                            <Radio value="2">Obstipação</Radio>

                        </Radio.Group>

                        <FormControl.Label>Quantas vezes por dia?</FormControl.Label>
                        <Input name="hora_admissao" w={130} />

                        <FormControl.Label>Ha quantos dias?</FormControl.Label>
                        <Input name="hora_admissao" w={130} />
                    </Box>

                    <Box bg="gray.50" rounded="lg" borderColor="coolGray.200" borderWidth="1" p={10} w="30%" shadow={2} minH={300}>
                        <FormControl.Label>Conduta nutricional?</FormControl.Label>
                        <TextArea></TextArea>
                    </Box>
                </HStack>
            </FormControl>
        </View>
    );

}