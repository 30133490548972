
    const hospitais = [
        {
            "id_hospital" : 3,
            "ds_hospital" : "HOSPITAL MARIA AUXILIADORA"
        },
        {
            "id_hospital" : 1,
            "ds_hospital" : "HOSPITAL SANTA HELENA"
        },
        {
            "id_hospital" : 4,
            "ds_hospital" : "HOSPITAL SANTA LUCIA"
        },
        {
            "id_hospital" : 5,
            "ds_hospital" : "HOSPITAL SÃO FRANCISCO"
        },
        {
            "id_hospital" : 6,
            "ds_hospital" : "HOSPITAL DO CORAÇÃO"
        },
        {
            "id_hospital" : 7,
            "ds_hospital" : "HOSPITAL SANTA LUZIA"
        },
        {
            "id_hospital" : 8,
            "ds_hospital" : "HOSPITAL DF STAR"
        },
        {
            "id_hospital" : 9,
            "ds_hospital" : "HOSPITAL DE TESTES"
        },
        {
            "id_hospital" : 10,
            "ds_hospital" : "HOSPITAL SANTA LÚCIA NORTE"
        },
        {
            "id_hospital" : 11,
            "ds_hospital" : "HOSPITAL ANCHIETA"
        },
        {
            "id_hospital" : 12,
            "ds_hospital" : "HOSPITAL VILA NOVA STAR"
        }
        
     
        
        
    ];
export default hospitais;
    