const unidades = [
        {
            "id" : 114,
            "hospitais_id" : 2,
            "id_hospital" : 10,
            "unidade" : "UTI_C"
        },
        {
            "id" : 48,
            "hospitais_id" : 4,
            "id_hospital" : 4,
            "unidade" : "UTI_NEO"
        },
        {
            "id" : 54,
            "hospitais_id" : 5,
            "id_hospital" : 5,
            "unidade" : "UTI_NEONATAL"
        },
        {
            "id" : 55,
            "hospitais_id" : 5,
            "id_hospital" : 5,
            "unidade" : "UTI_PEDIATRICA"
        },
        {
            "id" : 59,
            "hospitais_id" : 4,
            "id_hospital" : 4,
            "unidade" : "UTI_PED"
        },
        {
            "id" : 71,
            "hospitais_id" : 6,
            "id_hospital" : 7,
            "unidade" : "UTI_NEO"
        },
        {
            "id" : 72,
            "hospitais_id" : 6,
            "id_hospital" : 7,
            "unidade" : "UTI_PED"
        },
        {
            "id" : 76,
            "hospitais_id" : 7,
            "id_hospital" : 6,
            "unidade" : "UCO"
        },
        {
            "id" : 90,
            "hospitais_id" : 4,
            "id_hospital" : 4,
            "unidade" : "UTI_B3"
        },
        {
            "id" : 92,
            "hospitais_id" : 4,
            "id_hospital" : 4,
            "unidade" : "UTI_B1"
        },
        {
            "id" : 93,
            "hospitais_id" : 4,
            "id_hospital" : 4,
            "unidade" : "UTI_B2"
        },
        {
            "id" : 101,
            "hospitais_id" : 4,
            "id_hospital" : 4,
            "unidade" : "4º_C_ONCOLOGIA"
        },
        {
            "id" : 100,
            "hospitais_id" : 4,
            "id_hospital" : 4,
            "unidade" : "4º_C_CARDIOLOGIA"
        },
        {
            "id" : 103,
            "hospitais_id" : 1,
            "id_hospital" : 1,
            "unidade" : "MATERNIDADE"
        },
        {
            "id" : 111,
            "hospitais_id" : 9,
            "id_hospital" : 11,
            "unidade" : "UTI_NEO"
        },
        {
            "id" : 105,
            "hospitais_id" : 9,
            "id_hospital" : 11,
            "unidade" : "MATERNIDADE"
        },
        {
            "id" : 106,
            "hospitais_id" : 9,
            "id_hospital" : 11,
            "unidade" : "CLINICA_CIRURGICA"
        },
        {
            "id" : 107,
            "hospitais_id" : 9,
            "id_hospital" : 11,
            "unidade" : "CLINICA_MEDICA"
        },
        {
            "id" : 108,
            "hospitais_id" : 9,
            "id_hospital" : 11,
            "unidade" : "ONCOLOGIA"
        },
        {
            "id" : 110,
            "hospitais_id" : 9,
            "id_hospital" : 11,
            "unidade" : "UTI_ADULTO"
        },
        {
            "id" : 113,
            "hospitais_id" : 9,
            "id_hospital" : 11,
            "unidade" : "ALA_COVID"
        },
        {
            "id" : 112,
            "hospitais_id" : 9,
            "id_hospital" : 11,
            "unidade" : "UTI_PED"
        },
        {
            "id" : 3,
            "hospitais_id" : 3,
            "id_hospital" : 3,
            "unidade" : "5º_ANDAR"
        },
        {
            "id" : 4,
            "hospitais_id" : 3,
            "id_hospital" : 3,
            "unidade" : "6º_ANDAR"
        },
        {
            "id" : 5,
            "hospitais_id" : 3,
            "id_hospital" : 3,
            "unidade" : "7º_ANDAR"
        },
        {
            "id" : 6,
            "hospitais_id" : 3,
            "id_hospital" : 3,
            "unidade" : "8º_ANDAR"
        },
        {
            "id" : 9,
            "hospitais_id" : 3,
            "id_hospital" : 3,
            "unidade" : "MATERNIDADE"
        },
        {
            "id" : 10,
            "hospitais_id" : 3,
            "id_hospital" : 3,
            "unidade" : "PEDIATRIA"
        },
        {
            "id" : 16,
            "hospitais_id" : 1,
            "id_hospital" : 1,
            "unidade" : "2º_ANDAR"
        },
        {
            "id" : 17,
            "hospitais_id" : 1,
            "id_hospital" : 1,
            "unidade" : "3º_ANDAR"
        },
        {
            "id" : 18,
            "hospitais_id" : 1,
            "id_hospital" : 1,
            "unidade" : "4º_ANDAR"
        },
        {
            "id" : 19,
            "hospitais_id" : 1,
            "id_hospital" : 1,
            "unidade" : "5º_ANDAR"
        },
        {
            "id" : 27,
            "hospitais_id" : 2,
            "id_hospital" : 10,
            "unidade" : "3º_ANDAR"
        },
        {
            "id" : 28,
            "hospitais_id" : 2,
            "id_hospital" : 10,
            "unidade" : "4º_ANDAR"
        },
        {
            "id" : 29,
            "hospitais_id" : 2,
            "id_hospital" : 10,
            "unidade" : "5º_ANDAR"
        },
        {
            "id" : 30,
            "hospitais_id" : 2,
            "id_hospital" : 10,
            "unidade" : "6º_ANDAR"
        },
        {
            "id" : 31,
            "hospitais_id" : 2,
            "id_hospital" : 10,
            "unidade" : "UTI"
        },
        {
            "id" : 37,
            "hospitais_id" : 4,
            "id_hospital" : 4,
            "unidade" : "4º_ANDAR_B"
        },
        {
            "id" : 41,
            "hospitais_id" : 4,
            "id_hospital" : 4,
            "unidade" : "MATERNIDADE"
        },
        {
            "id" : 42,
            "hospitais_id" : 4,
            "id_hospital" : 4,
            "unidade" : "PEDIATRIA"
        },
        {
            "id" : 45,
            "hospitais_id" : 4,
            "id_hospital" : 4,
            "unidade" : "UTI_C1"
        },
        {
            "id" : 46,
            "hospitais_id" : 4,
            "id_hospital" : 4,
            "unidade" : "UTI_C2"
        },
        {
            "id" : 47,
            "hospitais_id" : 4,
            "id_hospital" : 4,
            "unidade" : "UTI_C3"
        },
        {
            "id" : 49,
            "hospitais_id" : 5,
            "id_hospital" : 5,
            "unidade" : "CLINICA_MEDICA"
        },
        {
            "id" : 50,
            "hospitais_id" : 5,
            "id_hospital" : 5,
            "unidade" : "PEDIATRIA"
        },
        {
            "id" : 51,
            "hospitais_id" : 5,
            "id_hospital" : 5,
            "unidade" : "CLINICA_CIRURGICA"
        },
        {
            "id" : 52,
            "hospitais_id" : 5,
            "id_hospital" : 5,
            "unidade" : "MATERNIDADE"
        },
        {
            "id" : 57,
            "hospitais_id" : 4,
            "id_hospital" : 4,
            "unidade" : "2º_ANDAR_C"
        },
        {
            "id" : 62,
            "hospitais_id" : 6,
            "id_hospital" : 7,
            "unidade" : "CLINICA_MEDICA"
        },
        {
            "id" : 63,
            "hospitais_id" : 6,
            "id_hospital" : 7,
            "unidade" : "CLINICA_CIRURGICA"
        },
        {
            "id" : 64,
            "hospitais_id" : 6,
            "id_hospital" : 7,
            "unidade" : "CLINICA_ONCOLOGICA"
        },
        {
            "id" : 65,
            "hospitais_id" : 6,
            "id_hospital" : 7,
            "unidade" : "CLINICA_PEDIATRICA"
        },
        {
            "id" : 66,
            "hospitais_id" : 6,
            "id_hospital" : 7,
            "unidade" : "MATERNIDADE"
        },
        {
            "id" : 73,
            "hospitais_id" : 7,
            "id_hospital" : 6,
            "unidade" : "CLINICA_CARDIOLOGICA_1"
        },
        {
            "id" : 75,
            "hospitais_id" : 7,
            "id_hospital" : 6,
            "unidade" : "UTI_CARDIOLOGICA"
        },
        {
            "id" : 80,
            "hospitais_id" : 8,
            "id_hospital" : 8,
            "unidade" : "TERREO"
        },
        {
            "id" : 81,
            "hospitais_id" : 8,
            "id_hospital" : 8,
            "unidade" : "CTI"
        },
        {
            "id" : 83,
            "hospitais_id" : 8,
            "id_hospital" : 8,
            "unidade" : "1º_ANDAR"
        },
        {
            "id" : 84,
            "hospitais_id" : 8,
            "id_hospital" : 8,
            "unidade" : "2º_ANDAR"
        },
        {
            "id" : 85,
            "hospitais_id" : 2,
            "id_hospital" : 10,
            "unidade" : "UTI_B"
        },
        {
            "id" : 86,
            "hospitais_id" : 2,
            "id_hospital" : 10,
            "unidade" : "PEDIATRIA"
        },
        {
            "id" : 87,
            "hospitais_id" : 4,
            "id_hospital" : 4,
            "unidade" : "CLINICA_CIRURGICA"
        },
        {
            "id" : 88,
            "hospitais_id" : 4,
            "id_hospital" : 4,
            "unidade" : "GERIATRIA"
        },
        {
            "id" : 36,
            "hospitais_id" : 4,
            "id_hospital" : 4,
            "unidade" : "3º_ANDAR_B"
        },
        {
            "id" : 1,
            "hospitais_id" : 3,
            "id_hospital" : 3,
            "unidade" : "1º_ANDAR"
        },
        {
            "id" : 12,
            "hospitais_id" : 3,
            "id_hospital" : 3,
            "unidade" : "UTI_ADULTO_B"
        },
        {
            "id" : 25,
            "hospitais_id" : 2,
            "id_hospital" : 10,
            "unidade" : "1º_ANDAR"
        },
        {
            "id" : 11,
            "hospitais_id" : 3,
            "id_hospital" : 3,
            "unidade" : "UTI_ADULTO_A"
        },
        {
            "id" : 77,
            "hospitais_id" : 1,
            "id_hospital" : 1,
            "unidade" : "UTI_NEO"
        },
        {
            "id" : 67,
            "hospitais_id" : 6,
            "id_hospital" : 7,
            "unidade" : "UTI_ADULTO_1"
        },
        {
            "id" : 79,
            "hospitais_id" : 1,
            "id_hospital" : 1,
            "unidade" : "UTI_PED"
        },
        {
            "id" : 68,
            "hospitais_id" : 6,
            "id_hospital" : 7,
            "unidade" : "UTI_ADULTO_2"
        },
        {
            "id" : 15,
            "hospitais_id" : 1,
            "id_hospital" : 1,
            "unidade" : "PEDIATRIA"
        },
        {
            "id" : 82,
            "hospitais_id" : 8,
            "id_hospital" : 8,
            "unidade" : "TMO_Setor"
        },
        {
            "id" : 118,
            "hospitais_id" : 4,
            "id_hospital" : 4,
            "unidade" : "UTI_B_4"
        },
        {
            "id" : 119,
            "hospitais_id" : 3,
            "id_hospital" : 3,
            "unidade" : "UTI_A2"
        },
        {
            "id" : 120,
            "hospitais_id" : 8,
            "id_hospital" : 8,
            "unidade" : "UTICARDIO"
        },
        {
            "id" : 122,
            "hospitais_id" : 3,
            "id_hospital" : 3,
            "unidade" : "UTI_C"
        },
        {
            "id" : 123,
            "hospitais_id" : 3,
            "id_hospital" : 3,
            "unidade" : "UTI_D"
        },
        {
            "id" : 124,
            "hospitais_id" : 1,
            "id_hospital" : 1,
            "unidade" : "UTI_CLINICA"
        },
        {
            "id" : 140,
            "hospitais_id" : 11,
            "id_hospital" : 12,
            "unidade" : "9º_ANDAR"
        },
        {
            "id" : 141,
            "hospitais_id" : 11,
            "id_hospital" : 12,
            "unidade" : "14º_ANDAR"
        },
        {
            "id" : 142,
            "hospitais_id" : 11,
            "id_hospital" : 12,
            "unidade" : "8º_ANDAR"
        },
        {
            "id" : 143,
            "hospitais_id" : 11,
            "id_hospital" : 12,
            "unidade" : "15º_ANDAR"
        },
        {
            "id" : 144,
            "hospitais_id" : 11,
            "id_hospital" : 12,
            "unidade" : "16º_ANDAR"
        },
        {
            "id" : 148,
            "hospitais_id" : 11,
            "id_hospital" : 12,
            "unidade" : "ONCO_13º_ANDAR"
        },
        {
            "id" : 149,
            "hospitais_id" : 11,
            "id_hospital" : 12,
            "unidade" : "TMO_13º_ANDAR"
        },
        {
            "id" : 139,
            "hospitais_id" : 11,
            "id_hospital" : 12,
            "unidade" : "UTI_4º_ANDAR"
        },
        {
            "id" : 145,
            "hospitais_id" : 11,
            "id_hospital" : 12,
            "unidade" : "UTI_10º_ANDAR"
        },
        {
            "id" : 146,
            "hospitais_id" : 11,
            "id_hospital" : 12,
            "unidade" : "UTI_11º_ANDAR"
        },
        {
            "id" : 147,
            "hospitais_id" : 11,
            "id_hospital" : 12,
            "unidade" : "UTI_CARDIO_12º_ANDAR"
        },
        {
            "id" : 150,
            "hospitais_id" : 2,
            "id_hospital" : 10,
            "unidade" : "UTI_PED"
        },
        {
            "id" : 151,
            "hospitais_id" : 5,
            "id_hospital" : 5,
            "unidade" : "UTI_ADULTO_1"
        },
        {
            "id" : 129,
            "hospitais_id" : 6,
            "id_hospital" : 7,
            "unidade" : "UTI_ADULTO_4"
        },
        {
            "id" : 130,
            "hospitais_id" : 6,
            "id_hospital" : 7,
            "unidade" : "UTI_ADULTO_5"
        },
        {
            "id" : 152,
            "hospitais_id" : 5,
            "id_hospital" : 5,
            "unidade" : "UTI_ADULTO_2"
        },
        {
            "id" : 153,
            "hospitais_id" : 9,
            "id_hospital" : 11,
            "unidade" : "2°_ALA_B"
        },
        {
            "id" : 131,
            "hospitais_id" : 6,
            "id_hospital" : 7,
            "unidade" : "UTI_COVID"
        },
        {
            "id" : 132,
            "hospitais_id" : 4,
            "id_hospital" : 4,
            "unidade" : "UTI_D"
        },
        {
            "id" : 133,
            "hospitais_id" : 7,
            "id_hospital" : 6,
            "unidade" : "UTI_NEURO"
        },
        {
            "id" : 69,
            "hospitais_id" : 6,
            "id_hospital" : 7,
            "unidade" : "UTI_CIRURGICA"
        },
        {
            "id" : 26,
            "hospitais_id" : 2,
            "id_hospital" : 10,
            "unidade" : "2º_ANDAR"
        },
        {
            "id" : 154,
            "hospitais_id" : 9,
            "id_hospital" : 11,
            "unidade" : "2°_ALA_D"
        },
        {
            "id" : 134,
            "hospitais_id" : 8,
            "id_hospital" : 8,
            "unidade" : "UAC"
        },
        {
            "id" : 126,
            "hospitais_id" : 1,
            "id_hospital" : 1,
            "unidade" : "UTI_CARDIO"
        },
        {
            "id" : 135,
            "hospitais_id" : 1,
            "id_hospital" : 1,
            "unidade" : "UTI_CIRURGICA"
        },
        {
            "id" : 127,
            "hospitais_id" : 9,
            "id_hospital" : 11,
            "unidade" : "UTI_3º_ALA-C_CRONICOS"
        },
        {
            "id" : 136,
            "hospitais_id" : 9,
            "id_hospital" : 11,
            "unidade" : "UTI_3º_ALA-A_NEUROCARDIO"
        },
        {
            "id" : 137,
            "hospitais_id" : 8,
            "id_hospital" : 8,
            "unidade" : "TX_ Orgaos_Solidos"
        },
        {
            "id" : 138,
            "hospitais_id" : 8,
            "id_hospital" : 8,
            "unidade" : "TCTH_Linha_Cuidado"
        },
        {
            "id" : 155,
            "hospitais_id" : 6,
            "id_hospital" : 7,
            "unidade" : "UTI_D"
        },
        {
            "id" : 167,
            "hospitais_id" : 3,
            "id_hospital" : 3,
            "unidade" : "UTI_E"
        },
        {
            "id" : 168,
            "hospitais_id" : 9,
            "id_hospital" : 9,
            "unidade" : "1º_ANDAR"
        },
        {
            "id" : 169,
            "hospitais_id" : 9,
            "id_hospital" : 9,
            "unidade" : "2º_ANDAR"
        }
    ];

export default unidades;
    