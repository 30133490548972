import React,{ useEffect, useState } from "react";
import { Center, Heading, Text, VStack, FormControl, Box, Input, Link, Button, HStack, Image, StatusBar, Container, IconButton, Icon, View, FlatList, Flex, Spacer, Divider, Select, CheckIcon, ScrollView, Alert, CloseIcon, useToast, Spinner } from "native-base";
import {MaterialIcons, Ionicons} from "@expo/vector-icons";
import indicadores from "../data/indicadores";
import unidades from "../data/unidades";
import moment from "moment";
import AsyncStorageLib from "@react-native-async-storage/async-storage";

export default function Estatistica ({route, navigation})
{  
    const [dados, setDados] = useState([]);

    
    const getData = async () => {
        try {
            const value = await AsyncStorageLib.getItem('@usuario');
            const retorno = JSON.parse(value);
            
            if(value !== null) {
                setDados(retorno);
            }
        } catch(e) {
            // error reading value
        }
    }
    
    
    useEffect(() => {
        getData();
    }, []);




    const unidades_hospital = unidades.filter(e => e.id_hospital === parseInt(dados.id_hospital));
    
    let [ano, setAno] = React.useState(moment().format("YYYY"));
    let [mes, setMes] = React.useState(moment().format("M"));
    let [dia, setDia] = React.useState(moment().format("D"));
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [unidade, setUnidade] = useState([]);
    const [mensagem, setMensagem] = useState('');

    const [q1, setQ1] = useState('');
    const [q2, setQ2] = useState(0);
    const [q3, setQ3] = useState('');
    const [q4, setQ4] = useState('');
    const [q5, setQ5] = useState('');
    const [q6, setQ6] = useState('');
    const [q7, setQ7] = useState('');
    const [q8, setQ8] = useState('');
    const [q9, setQ9] = useState('');
    const [q10, setQ10] = useState('');
    const [q11, setQ11] = useState('');
    const [q12, setQ12] = useState('');
    const [q13, setQ13] = useState('');
    const [q14, setQ14] = useState('');
    const [q15, setQ15] = useState('');
    const [q16, setQ16] = useState('');
    const [q17, setQ17] = useState('');
    const [q18, setQ18] = useState('');
    const [q19, setQ19] = useState('');
    const [q20, setQ20] = useState('');
    const [q21, setQ21] = useState('');
    const [q22, setQ22] = useState('');
    const [q23, setQ23] = useState('');
    const [q24, setQ24] = useState('');
    const [q25, setQ25] = useState('');
    const [q26, setQ26] = useState('');
    const [q27, setQ27] = useState('');
    const [q28, setQ28] = useState('');
    const [q29, setQ29] = useState('');
    const [q30, setQ30] = useState('');
    const [q31, setQ31] = useState('');
    const [q32, setQ32] = useState('');
    const [q33, setQ33] = useState('');
    const [q34, setQ34] = useState('');
    const [q35, setQ35] = useState('');
    const [q36, setQ36] = useState('');
    const [q37, setQ37] = useState('');
    const [q38, setQ38] = useState('');
    const [q39, setQ39] = useState('');
    const [q40, setQ40] = useState('');
    const [q41, setQ41] = useState('');
    const [q42, setQ42] = useState('');
    const [q43, setQ43] = useState('');
    const [q44, setQ44] = useState('');
    const [q45, setQ45] = useState('');
    const [q46, setQ46] = useState('');
    const [q47, setQ47] = useState('');
    const [q48, setQ48] = useState('');
    const [q49, setQ49] = useState('');
    const [q50, setQ50] = useState('');
    const [q51, setQ51] = useState('');
    const [q52, setQ52] = useState('');
    const [q53, setQ53] = useState('');
    const [q54, setQ54] = useState('');
    const [q55, setQ55] = useState('');
    const [q56, setQ56] = useState('');
    const [q57, setQ57] = useState('');
    const [q58, setQ58] = useState('');
    const [q59, setQ59] = useState('');
    const [q60, setQ60] = useState('');
    const [q61, setQ61] = useState('');
    const [q62, setQ62] = useState('');
    const [q63, setQ63] = useState('');
    const [q64, setQ64] = useState('');
    const [q65, setQ65] = useState('');
    const [q66, setQ66] = useState('');
    const [q67, setQ67] = useState('');
    const [q68, setQ68] = useState('');
    const [q69, setQ69] = useState('');
    const [q70, setQ70] = useState('');
    const [q71, setQ71] = useState('');
    const [q72, setQ72] = useState('');
    const [q73, setQ73] = useState('');
    const [q74, setQ74] = useState('');
    const [q75, setQ75] = useState('');
    const [q76, setQ76] = useState('');
    const [q77, setQ77] = useState('');
    const [q78, setQ78] = useState('');
    const [q79, setQ79] = useState('');
    const [q80, setQ80] = useState('');
    const [q81, setQ81] = useState('');
    const [q82, setQ82] = useState('');
    const [q83, setQ83] = useState('');
    const [q84, setQ84] = useState('');
    const [q85, setQ85] = useState('');
    const [q86, setQ86] = useState('');

    const [mostrar, setMostrar] = useState(false);
    const [textoValidacao, setTextoValidacao] = useState([]);
    
    const [inQ7, setInQ7] = useState(false);
    const [inQ11, setInQ11] = useState(false);
    const [inQ21, setInQ21] = useState(false);
    const [inQ22, setInQ22] = useState(false);
    const [inQ50, setInQ50] = useState(false);
    const [inQ52, setInQ52] = useState(false);
    
    /*useEffect(() => {
        fetch('https://sisibranutro.com.br/appEstatistica/api.php?token=indicador')
            .then((response) => response.json())
            .then((json) => setData(json))
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        fetch('https://sisibranutro.com.br/appEstatistica/api.php?token=unidade&id_hospital=12')
            .then((response) => response.json())
            .then((json) => setUnidade(json))
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
    }, []);*/

    function call_others(function_name, value) {
        value = value.replace(/[^0-9]/g, '');
        eval("setQ"+function_name+"('"+value+"')");
        //console.log("Valor agora: "+eval("q"+function_name))
    }

    const handleChange = (questao, valor) => {
        call_others(questao, valor); 
    }
    const toast = useToast();

    const buscarDados = async () => {
        setLoading(true);
        setMensagem('Buscando dados 😬');
        
        if(unidade == '')
        {
            toast.show({
                render: () => {
                  return <Box bg="danger.600" px="2" py="1" rounded="sm" mb={5}>
                          Preencha a unidade
                        </Box>;
                },
                placement: "top"
            });
            setLoading(false);
            return false;
        }

        const params = {
            token: 'buscar',
            id_hospital: dados.id_hospital,
            nu_ano: ano,
            nu_mes: mes,
            nu_dia: dia,
            id_unidade: unidade,
            id_usuario: dados.id_usuario
        }


        fetch('https://sisibranutro.com.br/appEstatistica/api.php', {
            method: 'POST',
            headers: {
              //Accept: 'application/json',
              //'Content-Type': 'multipart/form-data',
              'Content-Type' : 'application/json'
            },
            body: JSON.stringify(params),
          })
            .then((response) => response.json())
            .then((json) => {
                
                json.map((objeto) => (
                    call_others(objeto.id_indicador, objeto.nu_valor)
                ))

            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));

    }

    

    const handleSubmit = () => {
        setLoading(true);
        setMensagem('Gravando dados 🙃');
        
        if(unidade == '')
        {
            toast.show({
                render: () => {
                    return <Box bg="danger.600" px="2" py="1" rounded="sm" mb={5}>
                          Preencha a unidade
                        </Box>;
                },
                placement: "top"
            });
            setLoading(false);
            return false;
        }

        const params = {
            id_hospital : dados.id_hospital,
            id_usuario: dados.id_usuario,
            ano: ano,
            mes: mes,
            unidade: unidade,
            dia: dia,
            q1: '',
            q2: q2,
            q3: q3,
            q4: q4,
            q5: q5,
            q6: q6,
            q7: q7,
            q8: q8,
            q9: '',
            q10: q10,
            q11: q11,
            q12: q12,
            q13: q13,
            q14: q14,
            q15: q15,
            q16: q16,
            q17: q17,
            q18: q18,
            q19: q19,
            q20: q20,
            q21: q21,
            q22: q22,
            q23: '',
            q24: q24,
            q25: q25,
            q26: q26,
            q27: q27,
            q28: q28,
            q29: q29,
            q30: q30,
            q31: q31,
            q32: q32,
            q33: q33,
            q34: q34,
            q35: q35,
            q36: q36,
            q37: q37,
            q38: q38,
            q39: q39,
            q40: '',
            q41: q41,
            q42: q42,
            q43: q43,
            q44: q44,
            q45: q45,
            q46: q46,
            q47: q47,
            q48: q48,
            q49: q49,
            q50: q50,
            q51: '',
            q52: q52,
            q53: q53,
            q54: q54,
            q55: q55,
            q56: q56,
            q57: '',
            q58: q58,
            q59: q59,
            q60: q60,
            q61: q61,
            q62: q62,
            q63: q63,
            q64: q64,
            q65: q65,
            q66: q66,
            q67: q67,
            q68: q68,
            q69: q69,
            q70: q70,
            q71: q71,
            q72: q72,
            q73: q73,
            q74: q74,
            q75: q75,
            q76: q76,
            q77: q77,
            q78: q78,
            q79: '',
            q80: q80,
            q81: q81,
            q82: q82,
            q83: q83,
            q84: q84,
            q85: q85,
            q86: q86,
            token: 'gravar'
        }


        fetch('https://sisibranutro.com.br/appEstatistica/api.php', {
            method: 'POST',
            headers: {
              //Accept: 'application/json',
              //'Content-Type': 'multipart/form-data',
              'Content-Type' : 'application/json'
            },
            body: JSON.stringify(params),
          })
            .then((response) => response.json())
            .then((json) => toast.show({
                render: () => {
                  return <Box bg="emerald.500" px="2" py="1" rounded="sm" mb={5}>
                          {json.message} 😁
                        </Box>;
                },
                placement: "top"
            }))
            .catch((error) => console.error('Erro:'+error))
            .finally(() => setLoading(false));
    }

    const somaQ2 = () => {

        let valq3 = isNaN(parseInt(q3)) ? 0 : parseInt(q3);
        let valq4 = isNaN(parseInt(q4)) ? 0 : parseInt(q4);
        
        let total = valq3 + valq4
        
        if(!isNaN(total))
        {
            setQ2(total);
        }
        
    }

    const somaQ10 = () => {

        let valq3 = isNaN(parseInt(q3)) ? 0 : parseInt(q3);
        let valq4 = isNaN(parseInt(q4)) ? 0 : parseInt(q4);
        let valq5 = isNaN(parseInt(q5)) ? 0 : parseInt(q5);
        
        let total = valq3 + valq4 + valq5
        
        if(!isNaN(total))
        {
            setQ10(total);
        }
        
    }

    const validaQ7 = () => {
        let texto = ['⚠️ O valor da linha 7 deve ser igual ao da linha 3 ou seja, todo paciente com risco nutricional deve ter avaliação completa'];
        if(q3 !== q7)
        {
            setMostrar(true);
            setInQ7(true);
            setTextoValidacao([...textoValidacao, ...texto.filter(c => !textoValidacao.includes(c))]);    
        }
        else
        {
            var array = [...textoValidacao];
            var filter = array.filter(e => e !== texto[0]);
            if(filter.length == 0)
            {
                setTextoValidacao(filter);
                setMostrar(false);
                setInQ7(false);
            }
        }
    }

    const validaQ11 = () => {
        let valq11 = isNaN(parseInt(q11)) ? 0 : parseInt(q11);
        let valq12 = isNaN(parseInt(q12)) ? 0 : parseInt(q12);
        let valq13 = isNaN(parseInt(q13)) ? 0 : parseInt(q13);
        let valq14 = isNaN(parseInt(q14)) ? 0 : parseInt(q14);
        let valq15 = isNaN(parseInt(q15)) ? 0 : parseInt(q15);
        let valq16 = isNaN(parseInt(q16)) ? 0 : parseInt(q16);
        let valq17 = isNaN(parseInt(q17)) ? 0 : parseInt(q17);
        let valq18 = isNaN(parseInt(q18)) ? 0 : parseInt(q18);
        let valq19 = isNaN(parseInt(q19)) ? 0 : parseInt(q19);

        let soma = valq12 + valq13 + valq14 + valq15 + valq16 + valq17 + valq18 + valq19;
        console.log('soma:',soma);
        console.log('q11:', valq11);
        console.log('q15:', valq15);
        
        let texto = ['⚠️ O item 11 (Nº de pacientes revisitados) deve ser igual a soma das linhas 12+13+14+15+16+17+18+19'];
        if(valq11 != soma)
        {
            console.log('entrou no mostrar');
            setMostrar(true);    
            setInQ11(true);
            setTextoValidacao([...textoValidacao, ...texto.filter(c => !textoValidacao.includes(c))]);    
        }
        else
        {
            console.log('entrou no else');
            var array = [...textoValidacao];
            console.log('array:', array);
            var filter = array.filter(e => e !== texto[0]);
            setTextoValidacao(filter);
            console.log('entrou no não mostrar da 11. filtro:', filter);
            /*if(filter.length == 0)
            {
                setMostrar(false);
                setInQ11(false);
            }*/
        }
        
        
    }

    const validaQ21 = () => {
        let texto = ['⚠️ Todo paciente com risco nutricional deve ter avaliação nutricional completa, logo, a linha 20 deve ser igual a linha 21'];
        if(q20 !== q21)
        {
            setMostrar(true);
            setInQ21(true);
            setTextoValidacao([...textoValidacao, ...texto.filter(c => !textoValidacao.includes(c))]);    
        }
        else
        {
            var array = [...textoValidacao];
            var filter = array.filter(e => e !== texto[0]);
            if(filter.length == 0)
            {
                setTextoValidacao(filter);
                setMostrar(false);
                setInQ21(false);
            }
        }
    }

    const validaQ22 = () => {
        let texto = ['⚠️ Todo paciente em uso de terapia nutricional deve ter avaliação nutricional completa, logo, a linha 22 deve ser igual a linha 19 - linha 21).'];
        let valq19 = isNaN(parseInt(q19)) ? 0 : parseInt(q19);
        let valq21 = isNaN(parseInt(q21)) ? 0 : parseInt(q21);
        let soma = valq19 - valq21;
        
        if(parseInt(q22) !== soma && !isNaN(parseInt(q22)))
        {
            setMostrar(true);
            setInQ22(true);
            setTextoValidacao([...textoValidacao, ...texto.filter(c => !textoValidacao.includes(c))]);    
        }
        else
        {
            var array = [...textoValidacao];
            var filter = array.filter(e => e !== texto[0]);
            if(filter.length == 0)
            {
                setTextoValidacao(filter);
                setMostrar(false);
                setInQ22(false);
            }
        }
    }

    const validaQ50 = () => {
        let texto = ['⚠️ Todo paciente em TN deve ter avaliação nutricional completa, logo, a linha 49 deve ser igual a linha 50'];
        if(parseInt(q49) !== parseInt(q50))
        {
            setMostrar(true);
            setInQ50(true);
            setTextoValidacao([...textoValidacao, ...texto.filter(c => !textoValidacao.includes(c))]);    
        }
        else
        {
            var array = [...textoValidacao];
            var filter = array.filter(e => e !== texto[0]);
            if(filter.length == 0)
            {
                setTextoValidacao(filter);
                setMostrar(false);
                setInQ50(false);
            }
        }
    }

    const validaQ52 = () => {
        let texto = ['⚠️  A soma das linhas 8+12+15+17+19 deve ser igual a linha 52 '];
        let valq8 = isNaN(parseInt(q8)) ? 0 : parseInt(q8);
        let valq12 = isNaN(parseInt(q12)) ? 0 : parseInt(q12);
        let valq15 = isNaN(parseInt(q15)) ? 0 : parseInt(q15);
        let valq17 = isNaN(parseInt(q17)) ? 0 : parseInt(q17);
        let valq19 = isNaN(parseInt(q19)) ? 0 : parseInt(q19);
        let soma = valq8 + valq12 + valq15 + valq17 + valq19;
        
        if(parseInt(q52) !== soma && !isNaN(parseInt(q52)))
        {
            console.log('entrou no mostrar da 52');
            setMostrar(true);
            setInQ52(true);
            setTextoValidacao([...textoValidacao, ...texto.filter(c => !textoValidacao.includes(c))]);    
        }
        else
        {
            var array = [...textoValidacao];
            var filter = array.filter(e => e !== texto[0]);
            setTextoValidacao(filter);
            console.log('entrou no não mostrar do 52:', filter);
            /*if(filter.length == 0)
            {
                console.log('entrou no não mostrar da 52');
                setMostrar(false);
                setInQ52(false);
            }*/
        }
    }

    useEffect(() => {
        //console.log("Valor de q2:"+q2);    
    }, [q2]);

    useEffect(() => {
        //console.log("Valor de q10:"+q10);    
    }, [q10]);

    useEffect(() => {
        somaQ2();
        somaQ10();
    }, [q3]);

    useEffect(() => {
        somaQ2();
        somaQ10();
    }, [q4]);

    useEffect(() => {
        somaQ10();
    }, [q5]);

    useEffect(() => {
        validaQ7();
    }, [q7]);

    useEffect(() => {
        validaQ11();
    }, [q11,q12,q13,q14,q18]);

    useEffect(() => {
        validaQ11();
    }, [q15]);

    useEffect(() => {
        validaQ52();
    }, [q15]);

    useEffect(() => {
        validaQ11();
        validaQ52();
    }, [q16]);

    useEffect(() => {
        validaQ11();
        validaQ52();
    }, [q17]);

    useEffect(() => {
        validaQ11();
        validaQ52();
    }, [q19]);

    useEffect(() => {
        console.log('texto validação:',textoValidacao);
        if(textoValidacao.length == 0)
        {
            setMostrar(false);
            setInQ7(false);
            setInQ11(false);
            setInQ21(false);
            setInQ22(false);
            setInQ50(false);
            setInQ52(false);
        }
    }, [textoValidacao]);

    useEffect(() => {
        validaQ21();
    }, [q20,q21]);

    useEffect(() => {
        validaQ22();
    }, [q22]);

    useEffect(() => {
        validaQ50();
    }, [q49,q50]);

    useEffect(() => {
        validaQ52();
    }, [q8,q52]);


    return (
        <View bgColor="coolGray.200" flex={1}>
            <StatusBar barStyle="dark-content" />
            
            <HStack space={2} px="3" alignSelf={"flex-end"}>
                <Text alignSelf={"center"}>Olá, {dados.ds_nome}!</Text>
                <IconButton icon={<Icon as={MaterialIcons} name="arrow-back" size="md" />} onPress={() => navigation.navigate('Home')} />
            </HStack>
                
           
            <HStack >
                <Box pt={2} px={2} w={"100%"}>
                    { mostrar && 
                        <Alert status="error">
                            <VStack space={1} alignItems="center" >
                                <ScrollView h={50}>
                                    
                                    <Box _text={{
                                    textAlign: "left"
                                }} _dark={{
                                    _text: {
                                    color: "coolGray.600"
                                    }
                                }}>
                                    { textoValidacao }
                                    </Box>
                                </ScrollView>
                            </VStack>
                        </Alert>
                    }
                    {
                        isLoading && <Box alignItems={"center"}><Spinner accessibilityLabel="Carregando" /><Text>{mensagem}...</Text></Box>
                    }
                </Box>
                
            </HStack>
            <FormControl flex={1}>
                <HStack space={1} alignItems="flex-start" p="2" w="100%">
                    <Select accessibilityLabel="Ano" placeholder="Ano" selectedValue={ano} defaultValue={ano} onValueChange={itemValue => setAno(itemValue)} w="80px" id="ano" bgColor={"warmGray.100"}>
                        <Select.Item label="2021" value="2021" />
                        <Select.Item label="2022" value="2022" />
                    </Select>
                    <Select accessibilityLabel="Mes" placeholder="Mes" w="90px" selectedValue={mes} onValueChange={itemValue => setMes(itemValue)} id="mes" bgColor={"warmGray.100"}>
                        <Select.Item label="Janeiro" value="1" />
                        <Select.Item label="Fevereiro" value="2" />
                        <Select.Item label="Março" value="3" />
                        <Select.Item label="Abril" value="4" />
                        <Select.Item label="Maio" value="5" />
                        <Select.Item label="Junho" value="6" />
                        <Select.Item label="Julho" value="7" />
                        <Select.Item label="Agosto" value="8" />
                        <Select.Item label="Setembro" value="9" />
                        <Select.Item label="Outubro" value="10" />
                        <Select.Item label="Novembro" value="11" />
                        <Select.Item label="Dezembro" value="12" />
                    </Select>
                    <Select accessibilityLabel="Unidade" placeholder="Unidade" width="100px" onValueChange={itemValue => setUnidade(itemValue)} selectedValue={unidade} id="unidade" bgColor={"warmGray.100"}>
                        {
                            unidades_hospital.map((option) => (
                                <Select.Item label={option.unidade} value={option.id.toString()} key={option.id} />
                            ))
                        }
                    </Select>
                    <Select accessibilityLabel="Dia" placeholder="Dia" w="80px" selectedValue={dia} onValueChange={itemValue => setDia(itemValue)} id="dia" bgColor={"warmGray.100"}>
                        <Select.Item label="1" value="1" />
                        <Select.Item label="2" value="2" />
                        <Select.Item label="3" value="3" />
                        <Select.Item label="4" value="4" />
                        <Select.Item label="5" value="5" />
                        <Select.Item label="6" value="6" />
                        <Select.Item label="7" value="7" />
                        <Select.Item label="8" value="8" />
                        <Select.Item label="9" value="9" />
                        <Select.Item label="10" value="10" />
                        <Select.Item label="11" value="11" />
                        <Select.Item label="12" value="12" />
                        <Select.Item label="13" value="13" />
                        <Select.Item label="14" value="14" />
                        <Select.Item label="15" value="15" />
                        <Select.Item label="16" value="16" />
                        <Select.Item label="17" value="17" />
                        <Select.Item label="18" value="18" />
                        <Select.Item label="19" value="19" />
                        <Select.Item label="20" value="20" />
                        <Select.Item label="21" value="21" />
                        <Select.Item label="22" value="22" />
                        <Select.Item label="23" value="23" />
                        <Select.Item label="24" value="24" />
                        <Select.Item label="25" value="25" />
                        <Select.Item label="26" value="26" />
                        <Select.Item label="27" value="27" />
                        <Select.Item label="28" value="28" />
                        <Select.Item label="29" value="29" />
                        <Select.Item label="30" value="30" />
                        <Select.Item label="31" value="31" />
                    </Select>
                    <Button leftIcon={<Icon as={Ionicons} name="search" size="sm" />} size="sm" name="buscar" onPress={() => buscarDados()} mt={1}></Button>
                </HStack>
                
                <ScrollView flex={1}>
                    
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.300"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">1</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>ADMISSÃO</Text>
                            </HStack>
                            <Spacer />
                            <Text w={10} alignSelf={"center"}>1</Text>
                            <Text w={10} alignSelf="center">2</Text>
                            <Text w={10} alignSelf="center">3</Text>
                            <Text w={10}>4</Text>
                            <Text w={10}>5</Text>
                            <Text w={10}>6</Text>
                            <Text w={10}>7</Text>
                            <Text w={10}>8</Text>
                            <Text w={10}>9</Text>
                            <Text w={10}>10</Text>
                            <Text w={10}>11</Text>
                            <Text w={10}>12</Text>
                            <Text w={10}>13</Text>
                            <Text w={10}>14</Text>
                            <Text w={10}>15</Text>
                            <Text w={10}>16</Text>
                            <Text w={10}>17</Text>
                            <Text w={10}>18</Text>
                            <Text w={10}>19</Text>
                            <Text w={10}>20</Text>
                            <Text w={10}>21</Text>
                            <Text w={10}>22</Text>
                            <Text w={10}>23</Text>
                            <Text w={10}>24</Text>
                            <Text w={10}>25</Text>
                            <Text w={10}>26</Text>
                            <Text w={10}>27</Text>
                            <Text w={10}>28</Text>
                            <Text w={10}>29</Text>
                            <Text w={10}>30</Text>
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.200"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">2</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>TRIAGENS REALIZADAS</Text>
                            </HStack>
                            <Spacer />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador2" id="indicador2" value={q2.toString()} isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador2" id="indicador2" value={q2.toString()} isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador2" id="indicador2" value={q2.toString()} isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador2" id="indicador2" value={q2.toString()} isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador2" id="indicador2" value={q2.toString()} isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador2" id="indicador2" value={q2.toString()} isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador2" id="indicador2" value={q2.toString()} isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador2" id="indicador2" value={q2.toString()} isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador2" id="indicador2" value={q2.toString()} isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador2" id="indicador2" value={q2.toString()} isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador2" id="indicador2" value={q2.toString()} isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador2" id="indicador2" value={q2.toString()} isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador2" id="indicador2" value={q2.toString()} isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador2" id="indicador2" value={q2.toString()} isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador2" id="indicador2" value={q2.toString()} isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador2" id="indicador2" value={q2.toString()} isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador2" id="indicador2" value={q2.toString()} isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador2" id="indicador2" value={q2.toString()} isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador2" id="indicador2" value={q2.toString()} isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador2" id="indicador2" value={q2.toString()} isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador2" id="indicador2" value={q2.toString()} isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador2" id="indicador2" value={q2.toString()} isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador2" id="indicador2" value={q2.toString()} isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador2" id="indicador2" value={q2.toString()} isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador2" id="indicador2" value={q2.toString()} isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador2" id="indicador2" value={q2.toString()} isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador2" id="indicador2" value={q2.toString()} isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador2" id="indicador2" value={q2.toString()} isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador2" id="indicador2" value={q2.toString()} isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador2" id="indicador2" value={q2.toString()} isReadOnly />
                        </Flex>
                    </Box>

                    
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">3</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Triagens com risco nutricional</Text>
                            </HStack>
                            <Spacer />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => {setQ3(newValue)}}  name="indicador3" 
                                 id="indicador3" maxLength={3} keyboardType="numeric" value={q3} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => {setQ3(newValue)}}  name="indicador3" 
                                 id="indicador3" maxLength={3} keyboardType="numeric" value={q3} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => {setQ3(newValue)}}  name="indicador3" 
                                 id="indicador3" maxLength={3} keyboardType="numeric" value={q3} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => {setQ3(newValue)}}  name="indicador3" 
                                 id="indicador3" maxLength={3} keyboardType="numeric" value={q3} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => {setQ3(newValue)}}  name="indicador3" 
                                 id="indicador3" maxLength={3} keyboardType="numeric" value={q3} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => {setQ3(newValue)}}  name="indicador3" 
                                 id="indicador3" maxLength={3} keyboardType="numeric" value={q3} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => {setQ3(newValue)}}  name="indicador3" 
                                 id="indicador3" maxLength={3} keyboardType="numeric" value={q3} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => {setQ3(newValue)}}  name="indicador3" 
                                 id="indicador3" maxLength={3} keyboardType="numeric" value={q3} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => {setQ3(newValue)}}  name="indicador3" 
                                 id="indicador3" maxLength={3} keyboardType="numeric" value={q3} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => {setQ3(newValue)}}  name="indicador3" 
                                 id="indicador3" maxLength={3} keyboardType="numeric" value={q3} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => {setQ3(newValue)}}  name="indicador3" 
                                 id="indicador3" maxLength={3} keyboardType="numeric" value={q3} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => {setQ3(newValue)}}  name="indicador3" 
                                 id="indicador3" maxLength={3} keyboardType="numeric" value={q3} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => {setQ3(newValue)}}  name="indicador3" 
                                 id="indicador3" maxLength={3} keyboardType="numeric" value={q3} /><Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => {setQ3(newValue)}}  name="indicador3" 
                                 id="indicador3" maxLength={3} keyboardType="numeric" value={q3} /><Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => {setQ3(newValue)}}  name="indicador3" 
                                 id="indicador3" maxLength={3} keyboardType="numeric" value={q3} /><Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => {setQ3(newValue)}}  name="indicador3" 
                                 id="indicador3" maxLength={3} keyboardType="numeric" value={q3} /><Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => {setQ3(newValue)}}  name="indicador3" 
                                 id="indicador3" maxLength={3} keyboardType="numeric" value={q3} /><Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => {setQ3(newValue)}}  name="indicador3" 
                                 id="indicador3" maxLength={3} keyboardType="numeric" value={q3} /><Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => {setQ3(newValue)}}  name="indicador3" 
                                 id="indicador3" maxLength={3} keyboardType="numeric" value={q3} /><Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => {setQ3(newValue)}}  name="indicador3" 
                                 id="indicador3" maxLength={3} keyboardType="numeric" value={q3} /><Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => {setQ3(newValue)}}  name="indicador3" 
                                 id="indicador3" maxLength={3} keyboardType="numeric" value={q3} /><Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => {setQ3(newValue)}}  name="indicador3" 
                                 id="indicador3" maxLength={3} keyboardType="numeric" value={q3} /><Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => {setQ3(newValue)}}  name="indicador3" 
                                 id="indicador3" maxLength={3} keyboardType="numeric" value={q3} /><Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => {setQ3(newValue)}}  name="indicador3" 
                                 id="indicador3" maxLength={3} keyboardType="numeric" value={q3} /><Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => {setQ3(newValue)}}  name="indicador3" 
                                 id="indicador3" maxLength={3} keyboardType="numeric" value={q3} /><Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => {setQ3(newValue)}}  name="indicador3" 
                                 id="indicador3" maxLength={3} keyboardType="numeric" value={q3} /><Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => {setQ3(newValue)}}  name="indicador3" 
                                 id="indicador3" maxLength={3} keyboardType="numeric" value={q3} /><Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => {setQ3(newValue)}}  name="indicador3" 
                                 id="indicador3" maxLength={3} keyboardType="numeric" value={q3} /><Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => {setQ3(newValue)}}  name="indicador3" 
                                 id="indicador3" maxLength={3} keyboardType="numeric" value={q3} /><Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => {setQ3(newValue)}}  name="indicador3" 
                                 id="indicador3" maxLength={3} keyboardType="numeric" value={q3} />                        
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">4</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Triagens sem risco nutricional</Text>
                            </HStack>
                            <Spacer />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ4(newValue)}  name="indicador4" 
                                 id="indicador4" maxLength={3} keyboardType="numeric" value={q4} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ4(newValue)}  name="indicador4" 
                                 id="indicador4" maxLength={3} keyboardType="numeric" value={q4} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ4(newValue)}  name="indicador4" 
                                 id="indicador4" maxLength={3} keyboardType="numeric" value={q4} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ4(newValue)}  name="indicador4" 
                                 id="indicador4" maxLength={3} keyboardType="numeric" value={q4} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ4(newValue)}  name="indicador4" 
                                 id="indicador4" maxLength={3} keyboardType="numeric" value={q4} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ4(newValue)}  name="indicador4" 
                                 id="indicador4" maxLength={3} keyboardType="numeric" value={q4} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ4(newValue)}  name="indicador4" 
                                 id="indicador4" maxLength={3} keyboardType="numeric" value={q4} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ4(newValue)}  name="indicador4" 
                                 id="indicador4" maxLength={3} keyboardType="numeric" value={q4} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ4(newValue)}  name="indicador4" 
                                 id="indicador4" maxLength={3} keyboardType="numeric" value={q4} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ4(newValue)}  name="indicador4" 
                                 id="indicador4" maxLength={3} keyboardType="numeric" value={q4} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ4(newValue)}  name="indicador4" 
                                 id="indicador4" maxLength={3} keyboardType="numeric" value={q4} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ4(newValue)}  name="indicador4" 
                                 id="indicador4" maxLength={3} keyboardType="numeric" value={q4} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ4(newValue)}  name="indicador4" 
                                 id="indicador4" maxLength={3} keyboardType="numeric" value={q4} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ4(newValue)}  name="indicador4" 
                                 id="indicador4" maxLength={3} keyboardType="numeric" value={q4} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ4(newValue)}  name="indicador4" 
                                 id="indicador4" maxLength={3} keyboardType="numeric" value={q4} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ4(newValue)}  name="indicador4" 
                                 id="indicador4" maxLength={3} keyboardType="numeric" value={q4} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ4(newValue)}  name="indicador4" 
                                 id="indicador4" maxLength={3} keyboardType="numeric" value={q4} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ4(newValue)}  name="indicador4" 
                                 id="indicador4" maxLength={3} keyboardType="numeric" value={q4} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ4(newValue)}  name="indicador4" 
                                 id="indicador4" maxLength={3} keyboardType="numeric" value={q4} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ4(newValue)}  name="indicador4" 
                                 id="indicador4" maxLength={3} keyboardType="numeric" value={q4} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ4(newValue)}  name="indicador4" 
                                 id="indicador4" maxLength={3} keyboardType="numeric" value={q4} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ4(newValue)}  name="indicador4" 
                                 id="indicador4" maxLength={3} keyboardType="numeric" value={q4} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ4(newValue)}  name="indicador4" 
                                 id="indicador4" maxLength={3} keyboardType="numeric" value={q4} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ4(newValue)}  name="indicador4" 
                                 id="indicador4" maxLength={3} keyboardType="numeric" value={q4} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ4(newValue)}  name="indicador4" 
                                 id="indicador4" maxLength={3} keyboardType="numeric" value={q4} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ4(newValue)}  name="indicador4" 
                                 id="indicador4" maxLength={3} keyboardType="numeric" value={q4} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ4(newValue)}  name="indicador4" 
                                 id="indicador4" maxLength={3} keyboardType="numeric" value={q4} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ4(newValue)}  name="indicador4" 
                                 id="indicador4" maxLength={3} keyboardType="numeric" value={q4} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ4(newValue)}  name="indicador4" 
                                 id="indicador4" maxLength={3} keyboardType="numeric" value={q4} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ4(newValue)}  name="indicador4" 
                                 id="indicador4" maxLength={3} keyboardType="numeric" value={q4} />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">5</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Avaliação completa (gestantes)</Text>
                            </HStack>
                            <Spacer />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ5(newValue)} name="indicador5" 
                                value={q5} id="indicador5" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ5(newValue)} name="indicador5" 
                                value={q5} id="indicador5" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ5(newValue)} name="indicador5" 
                                value={q5} id="indicador5" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ5(newValue)} name="indicador5" 
                                value={q5} id="indicador5" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ5(newValue)} name="indicador5" 
                                value={q5} id="indicador5" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ5(newValue)} name="indicador5" 
                                value={q5} id="indicador5" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ5(newValue)} name="indicador5" 
                                value={q5} id="indicador5" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ5(newValue)} name="indicador5" 
                                value={q5} id="indicador5" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ5(newValue)} name="indicador5" 
                                value={q5} id="indicador5" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ5(newValue)} name="indicador5" 
                                value={q5} id="indicador5" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ5(newValue)} name="indicador5" 
                                value={q5} id="indicador5" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ5(newValue)} name="indicador5" 
                                value={q5} id="indicador5" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ5(newValue)} name="indicador5" 
                                value={q5} id="indicador5" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ5(newValue)} name="indicador5" 
                                value={q5} id="indicador5" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ5(newValue)} name="indicador5" 
                                value={q5} id="indicador5" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ5(newValue)} name="indicador5" 
                                value={q5} id="indicador5" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ5(newValue)} name="indicador5" 
                                value={q5} id="indicador5" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ5(newValue)} name="indicador5" 
                                value={q5} id="indicador5" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ5(newValue)} name="indicador5" 
                                value={q5} id="indicador5" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ5(newValue)} name="indicador5" 
                                value={q5} id="indicador5" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ5(newValue)} name="indicador5" 
                                value={q5} id="indicador5" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ5(newValue)} name="indicador5" 
                                value={q5} id="indicador5" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ5(newValue)} name="indicador5" 
                                value={q5} id="indicador5" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ5(newValue)} name="indicador5" 
                                value={q5} id="indicador5" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ5(newValue)} name="indicador5" 
                                value={q5} id="indicador5" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ5(newValue)} name="indicador5" 
                                value={q5} id="indicador5" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ5(newValue)} name="indicador5" 
                                value={q5} id="indicador5" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ5(newValue)} name="indicador5" 
                                value={q5} id="indicador5" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ5(newValue)} name="indicador5" 
                                value={q5} id="indicador5" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ5(newValue)} name="indicador5" 
                                value={q5} id="indicador5" maxLength={3} keyboardType="numeric" />
                            
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                                <HStack alignItems="flex-start" style={{textAlignVertical: 'center',
                                            flexDirection: "row",
                                            flex: 1,
                                            flexWrap: "wrap"}}>
                                <Text color="coolGray.800" w="5">6</Text>
                                <Text color="coolGray.800" w="80%" style={{fontSize: 12}}>Avaliação completa pacientes sem risco que irão iniciar TN</Text>
                            </HStack>
                            <Spacer />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ6(newValue)} name="indicador6" 
                                value={q6} id="indicador6" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ6(newValue)} name="indicador6" 
                                value={q6} id="indicador6" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ6(newValue)} name="indicador6" 
                                value={q6} id="indicador6" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ6(newValue)} name="indicador6" 
                                value={q6} id="indicador6" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ6(newValue)} name="indicador6" 
                                value={q6} id="indicador6" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ6(newValue)} name="indicador6" 
                                value={q6} id="indicador6" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ6(newValue)} name="indicador6" 
                                value={q6} id="indicador6" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ6(newValue)} name="indicador6" 
                                value={q6} id="indicador6" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ6(newValue)} name="indicador6" 
                                value={q6} id="indicador6" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ6(newValue)} name="indicador6" 
                                value={q6} id="indicador6" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ6(newValue)} name="indicador6" 
                                value={q6} id="indicador6" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ6(newValue)} name="indicador6" 
                                value={q6} id="indicador6" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ6(newValue)} name="indicador6" 
                                value={q6} id="indicador6" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ6(newValue)} name="indicador6" 
                                value={q6} id="indicador6" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ6(newValue)} name="indicador6" 
                                value={q6} id="indicador6" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ6(newValue)} name="indicador6" 
                                value={q6} id="indicador6" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ6(newValue)} name="indicador6" 
                                value={q6} id="indicador6" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ6(newValue)} name="indicador6" 
                                value={q6} id="indicador6" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ6(newValue)} name="indicador6" 
                                value={q6} id="indicador6" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ6(newValue)} name="indicador6" 
                                value={q6} id="indicador6" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ6(newValue)} name="indicador6" 
                                value={q6} id="indicador6" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ6(newValue)} name="indicador6" 
                                value={q6} id="indicador6" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ6(newValue)} name="indicador6" 
                                value={q6} id="indicador6" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ6(newValue)} name="indicador6" 
                                value={q6} id="indicador6" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ6(newValue)} name="indicador6" 
                                value={q6} id="indicador6" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ6(newValue)} name="indicador6" 
                                value={q6} id="indicador6" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ6(newValue)} name="indicador6" 
                                value={q6} id="indicador6" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ6(newValue)} name="indicador6" 
                                value={q6} id="indicador6" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ6(newValue)} name="indicador6" 
                                value={q6} id="indicador6" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ6(newValue)} name="indicador6" 
                                value={q6} id="indicador6" maxLength={3} keyboardType="numeric" />
                            
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">7</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Avaliação completa (pacientes com risco nutricional)</Text>
                            </HStack>
                            <Spacer />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ7(newValue)} name="indicador7" 
                                value={q7} id="indicador7" maxLength={3} keyboardType="numeric" isInvalid={inQ7} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ7(newValue)} name="indicador7" 
                                value={q7} id="indicador7" maxLength={3} keyboardType="numeric" isInvalid={inQ7} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ7(newValue)} name="indicador7" 
                                value={q7} id="indicador7" maxLength={3} keyboardType="numeric" isInvalid={inQ7} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ7(newValue)} name="indicador7" 
                                value={q7} id="indicador7" maxLength={3} keyboardType="numeric" isInvalid={inQ7} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ7(newValue)} name="indicador7" 
                                value={q7} id="indicador7" maxLength={3} keyboardType="numeric" isInvalid={inQ7} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ7(newValue)} name="indicador7" 
                                value={q7} id="indicador7" maxLength={3} keyboardType="numeric" isInvalid={inQ7} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ7(newValue)} name="indicador7" 
                                value={q7} id="indicador7" maxLength={3} keyboardType="numeric" isInvalid={inQ7} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ7(newValue)} name="indicador7" 
                                value={q7} id="indicador7" maxLength={3} keyboardType="numeric" isInvalid={inQ7} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ7(newValue)} name="indicador7" 
                                value={q7} id="indicador7" maxLength={3} keyboardType="numeric" isInvalid={inQ7} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ7(newValue)} name="indicador7" 
                                value={q7} id="indicador7" maxLength={3} keyboardType="numeric" isInvalid={inQ7} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ7(newValue)} name="indicador7" 
                                value={q7} id="indicador7" maxLength={3} keyboardType="numeric" isInvalid={inQ7} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ7(newValue)} name="indicador7" 
                                value={q7} id="indicador7" maxLength={3} keyboardType="numeric" isInvalid={inQ7} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ7(newValue)} name="indicador7" 
                                value={q7} id="indicador7" maxLength={3} keyboardType="numeric" isInvalid={inQ7} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ7(newValue)} name="indicador7" 
                                value={q7} id="indicador7" maxLength={3} keyboardType="numeric" isInvalid={inQ7} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ7(newValue)} name="indicador7" 
                                value={q7} id="indicador7" maxLength={3} keyboardType="numeric" isInvalid={inQ7} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ7(newValue)} name="indicador7" 
                                value={q7} id="indicador7" maxLength={3} keyboardType="numeric" isInvalid={inQ7} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ7(newValue)} name="indicador7" 
                                value={q7} id="indicador7" maxLength={3} keyboardType="numeric" isInvalid={inQ7} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ7(newValue)} name="indicador7" 
                                value={q7} id="indicador7" maxLength={3} keyboardType="numeric" isInvalid={inQ7} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ7(newValue)} name="indicador7" 
                                value={q7} id="indicador7" maxLength={3} keyboardType="numeric" isInvalid={inQ7} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ7(newValue)} name="indicador7" 
                                value={q7} id="indicador7" maxLength={3} keyboardType="numeric" isInvalid={inQ7} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ7(newValue)} name="indicador7" 
                                value={q7} id="indicador7" maxLength={3} keyboardType="numeric" isInvalid={inQ7} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ7(newValue)} name="indicador7" 
                                value={q7} id="indicador7" maxLength={3} keyboardType="numeric" isInvalid={inQ7} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ7(newValue)} name="indicador7" 
                                value={q7} id="indicador7" maxLength={3} keyboardType="numeric" isInvalid={inQ7} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ7(newValue)} name="indicador7" 
                                value={q7} id="indicador7" maxLength={3} keyboardType="numeric" isInvalid={inQ7} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ7(newValue)} name="indicador7" 
                                value={q7} id="indicador7" maxLength={3} keyboardType="numeric" isInvalid={inQ7} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ7(newValue)} name="indicador7" 
                                value={q7} id="indicador7" maxLength={3} keyboardType="numeric" isInvalid={inQ7} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ7(newValue)} name="indicador7" 
                                value={q7} id="indicador7" maxLength={3} keyboardType="numeric" isInvalid={inQ7} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ7(newValue)} name="indicador7" 
                                value={q7} id="indicador7" maxLength={3} keyboardType="numeric" isInvalid={inQ7} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ7(newValue)} name="indicador7" 
                                value={q7} id="indicador7" maxLength={3} keyboardType="numeric" isInvalid={inQ7} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ7(newValue)} name="indicador7" 
                                value={q7} id="indicador7" maxLength={3} keyboardType="numeric" isInvalid={inQ7} />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">8</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Nº de pacientes que iniciaram TN na admissão</Text>
                            </HStack>
                            <Spacer />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ8(newValue)} name="indicador8" 
                                value={q8} id="indicador8" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ8(newValue)} name="indicador8" 
                                value={q8} id="indicador8" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ8(newValue)} name="indicador8" 
                                value={q8} id="indicador8" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ8(newValue)} name="indicador8" 
                                value={q8} id="indicador8" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ8(newValue)} name="indicador8" 
                                value={q8} id="indicador8" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ8(newValue)} name="indicador8" 
                                value={q8} id="indicador8" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ8(newValue)} name="indicador8" 
                                value={q8} id="indicador8" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ8(newValue)} name="indicador8" 
                                value={q8} id="indicador8" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ8(newValue)} name="indicador8" 
                                value={q8} id="indicador8" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ8(newValue)} name="indicador8" 
                                value={q8} id="indicador8" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ8(newValue)} name="indicador8" 
                                value={q8} id="indicador8" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ8(newValue)} name="indicador8" 
                                value={q8} id="indicador8" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ8(newValue)} name="indicador8" 
                                value={q8} id="indicador8" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ8(newValue)} name="indicador8" 
                                value={q8} id="indicador8" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ8(newValue)} name="indicador8" 
                                value={q8} id="indicador8" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ8(newValue)} name="indicador8" 
                                value={q8} id="indicador8" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ8(newValue)} name="indicador8" 
                                value={q8} id="indicador8" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ8(newValue)} name="indicador8" 
                                value={q8} id="indicador8" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ8(newValue)} name="indicador8" 
                                value={q8} id="indicador8" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ8(newValue)} name="indicador8" 
                                value={q8} id="indicador8" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ8(newValue)} name="indicador8" 
                                value={q8} id="indicador8" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ8(newValue)} name="indicador8" 
                                value={q8} id="indicador8" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ8(newValue)} name="indicador8" 
                                value={q8} id="indicador8" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ8(newValue)} name="indicador8" 
                                value={q8} id="indicador8" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ8(newValue)} name="indicador8" 
                                value={q8} id="indicador8" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ8(newValue)} name="indicador8" 
                                value={q8} id="indicador8" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ8(newValue)} name="indicador8" 
                                value={q8} id="indicador8" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ8(newValue)} name="indicador8" 
                                value={q8} id="indicador8" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ8(newValue)} name="indicador8" 
                                value={q8} id="indicador8" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ8(newValue)} name="indicador8" 
                                value={q8} id="indicador8" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.300"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">9</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>INTERNAÇÃO</Text>
                            </HStack>
                            <Spacer />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.200"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">10</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Nº de pacientes visitados (admitidos)</Text>
                            </HStack>
                            <Spacer />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador10" 
                                value={q10} id="indicador10" maxLength={3} keyboardType="numeric" isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador10" 
                                value={q10} id="indicador10" maxLength={3} keyboardType="numeric" isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador10" 
                                value={q10} id="indicador10" maxLength={3} keyboardType="numeric" isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador10" 
                                value={q10} id="indicador10" maxLength={3} keyboardType="numeric" isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador10" 
                                value={q10} id="indicador10" maxLength={3} keyboardType="numeric" isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador10" 
                                value={q10} id="indicador10" maxLength={3} keyboardType="numeric" isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador10" 
                                value={q10} id="indicador10" maxLength={3} keyboardType="numeric" isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador10" 
                                value={q10} id="indicador10" maxLength={3} keyboardType="numeric" isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador10" 
                                value={q10} id="indicador10" maxLength={3} keyboardType="numeric" isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador10" 
                                value={q10} id="indicador10" maxLength={3} keyboardType="numeric" isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador10" 
                                value={q10} id="indicador10" maxLength={3} keyboardType="numeric" isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador10" 
                                value={q10} id="indicador10" maxLength={3} keyboardType="numeric" isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador10" 
                                value={q10} id="indicador10" maxLength={3} keyboardType="numeric" isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador10" 
                                value={q10} id="indicador10" maxLength={3} keyboardType="numeric" isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador10" 
                                value={q10} id="indicador10" maxLength={3} keyboardType="numeric" isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador10" 
                                value={q10} id="indicador10" maxLength={3} keyboardType="numeric" isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador10" 
                                value={q10} id="indicador10" maxLength={3} keyboardType="numeric" isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador10" 
                                value={q10} id="indicador10" maxLength={3} keyboardType="numeric" isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador10" 
                                value={q10} id="indicador10" maxLength={3} keyboardType="numeric" isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador10" 
                                value={q10} id="indicador10" maxLength={3} keyboardType="numeric" isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador10" 
                                value={q10} id="indicador10" maxLength={3} keyboardType="numeric" isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador10" 
                                value={q10} id="indicador10" maxLength={3} keyboardType="numeric" isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador10" 
                                value={q10} id="indicador10" maxLength={3} keyboardType="numeric" isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador10" 
                                value={q10} id="indicador10" maxLength={3} keyboardType="numeric" isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador10" 
                                value={q10} id="indicador10" maxLength={3} keyboardType="numeric" isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador10" 
                                value={q10} id="indicador10" maxLength={3} keyboardType="numeric" isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador10" 
                                value={q10} id="indicador10" maxLength={3} keyboardType="numeric" isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador10" 
                                value={q10} id="indicador10" maxLength={3} keyboardType="numeric" isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador10" 
                                value={q10} id="indicador10" maxLength={3} keyboardType="numeric" isReadOnly />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador10" 
                                value={q10} id="indicador10" maxLength={3} keyboardType="numeric" isReadOnly />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">11</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Nº de pacientes revisitados</Text>
                            </HStack>
                            <Spacer />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ11(newValue)} name="indicador11" 
                                value={q11} id="indicador11" maxLength={3} keyboardType="numeric" isInvalid={inQ11} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ11(newValue)} name="indicador11" 
                                value={q11} id="indicador11" maxLength={3} keyboardType="numeric" isInvalid={inQ11} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ11(newValue)} name="indicador11" 
                                value={q11} id="indicador11" maxLength={3} keyboardType="numeric" isInvalid={inQ11} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ11(newValue)} name="indicador11" 
                                value={q11} id="indicador11" maxLength={3} keyboardType="numeric" isInvalid={inQ11} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ11(newValue)} name="indicador11" 
                                value={q11} id="indicador11" maxLength={3} keyboardType="numeric" isInvalid={inQ11} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ11(newValue)} name="indicador11" 
                                value={q11} id="indicador11" maxLength={3} keyboardType="numeric" isInvalid={inQ11} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ11(newValue)} name="indicador11" 
                                value={q11} id="indicador11" maxLength={3} keyboardType="numeric" isInvalid={inQ11} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ11(newValue)} name="indicador11" 
                                value={q11} id="indicador11" maxLength={3} keyboardType="numeric" isInvalid={inQ11} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ11(newValue)} name="indicador11" 
                                value={q11} id="indicador11" maxLength={3} keyboardType="numeric" isInvalid={inQ11} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ11(newValue)} name="indicador11" 
                                value={q11} id="indicador11" maxLength={3} keyboardType="numeric" isInvalid={inQ11} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ11(newValue)} name="indicador11" 
                                value={q11} id="indicador11" maxLength={3} keyboardType="numeric" isInvalid={inQ11} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ11(newValue)} name="indicador11" 
                                value={q11} id="indicador11" maxLength={3} keyboardType="numeric" isInvalid={inQ11} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ11(newValue)} name="indicador11" 
                                value={q11} id="indicador11" maxLength={3} keyboardType="numeric" isInvalid={inQ11} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ11(newValue)} name="indicador11" 
                                value={q11} id="indicador11" maxLength={3} keyboardType="numeric" isInvalid={inQ11} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ11(newValue)} name="indicador11" 
                                value={q11} id="indicador11" maxLength={3} keyboardType="numeric" isInvalid={inQ11} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ11(newValue)} name="indicador11" 
                                value={q11} id="indicador11" maxLength={3} keyboardType="numeric" isInvalid={inQ11} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ11(newValue)} name="indicador11" 
                                value={q11} id="indicador11" maxLength={3} keyboardType="numeric" isInvalid={inQ11} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ11(newValue)} name="indicador11" 
                                value={q11} id="indicador11" maxLength={3} keyboardType="numeric" isInvalid={inQ11} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ11(newValue)} name="indicador11" 
                                value={q11} id="indicador11" maxLength={3} keyboardType="numeric" isInvalid={inQ11} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ11(newValue)} name="indicador11" 
                                value={q11} id="indicador11" maxLength={3} keyboardType="numeric" isInvalid={inQ11} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ11(newValue)} name="indicador11" 
                                value={q11} id="indicador11" maxLength={3} keyboardType="numeric" isInvalid={inQ11} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ11(newValue)} name="indicador11" 
                                value={q11} id="indicador11" maxLength={3} keyboardType="numeric" isInvalid={inQ11} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ11(newValue)} name="indicador11" 
                                value={q11} id="indicador11" maxLength={3} keyboardType="numeric" isInvalid={inQ11} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ11(newValue)} name="indicador11" 
                                value={q11} id="indicador11" maxLength={3} keyboardType="numeric" isInvalid={inQ11} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ11(newValue)} name="indicador11" 
                                value={q11} id="indicador11" maxLength={3} keyboardType="numeric" isInvalid={inQ11} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ11(newValue)} name="indicador11" 
                                value={q11} id="indicador11" maxLength={3} keyboardType="numeric" isInvalid={inQ11} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ11(newValue)} name="indicador11" 
                                value={q11} id="indicador11" maxLength={3} keyboardType="numeric" isInvalid={inQ11} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ11(newValue)} name="indicador11" 
                                value={q11} id="indicador11" maxLength={3} keyboardType="numeric" isInvalid={inQ11} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ11(newValue)} name="indicador11" 
                                value={q11} id="indicador11" maxLength={3} keyboardType="numeric" isInvalid={inQ11} />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ11(newValue)} name="indicador11" 
                                value={q11} id="indicador11" maxLength={3} keyboardType="numeric" isInvalid={inQ11} />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                        <HStack alignItems="flex-start" style={{textAlignVertical: 'center',
                                            flexDirection: "row",
                                            flex: 1,
                                            flexWrap: "wrap"}}>
                                <Text color="coolGray.800" w="5">12</Text>
                                <Text color="coolGray.800" w="80%" style={{fontSize: 12}}>Avaliação completa pacientes sem risco que irão iniciar TN </Text>
                            </HStack>
                            <Spacer />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ12(newValue)} name="indicador12" 
                                value={q12} id="indicador12" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ12(newValue)} name="indicador12" 
                                value={q12} id="indicador12" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ12(newValue)} name="indicador12" 
                                value={q12} id="indicador12" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ12(newValue)} name="indicador12" 
                                value={q12} id="indicador12" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ12(newValue)} name="indicador12" 
                                value={q12} id="indicador12" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ12(newValue)} name="indicador12" 
                                value={q12} id="indicador12" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ12(newValue)} name="indicador12" 
                                value={q12} id="indicador12" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ12(newValue)} name="indicador12" 
                                value={q12} id="indicador12" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ12(newValue)} name="indicador12" 
                                value={q12} id="indicador12" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ12(newValue)} name="indicador12" 
                                value={q12} id="indicador12" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ12(newValue)} name="indicador12" 
                                value={q12} id="indicador12" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ12(newValue)} name="indicador12" 
                                value={q12} id="indicador12" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ12(newValue)} name="indicador12" 
                                value={q12} id="indicador12" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ12(newValue)} name="indicador12" 
                                value={q12} id="indicador12" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ12(newValue)} name="indicador12" 
                                value={q12} id="indicador12" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ12(newValue)} name="indicador12" 
                                value={q12} id="indicador12" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ12(newValue)} name="indicador12" 
                                value={q12} id="indicador12" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ12(newValue)} name="indicador12" 
                                value={q12} id="indicador12" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ12(newValue)} name="indicador12" 
                                value={q12} id="indicador12" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ12(newValue)} name="indicador12" 
                                value={q12} id="indicador12" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ12(newValue)} name="indicador12" 
                                value={q12} id="indicador12" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ12(newValue)} name="indicador12" 
                                value={q12} id="indicador12" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ12(newValue)} name="indicador12" 
                                value={q12} id="indicador12" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ12(newValue)} name="indicador12" 
                                value={q12} id="indicador12" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ12(newValue)} name="indicador12" 
                                value={q12} id="indicador12" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ12(newValue)} name="indicador12" 
                                value={q12} id="indicador12" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ12(newValue)} name="indicador12" 
                                value={q12} id="indicador12" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ12(newValue)} name="indicador12" 
                                value={q12} id="indicador12" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ12(newValue)} name="indicador12" 
                                value={q12} id="indicador12" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ12(newValue)} name="indicador12" 
                                value={q12} id="indicador12" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">13</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Avaliação completa (pacientes da EMTN em dieta zero)</Text>
                            </HStack>
                            <Spacer />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ13(newValue)} name="indicador13" 
                                value={q13} id="indicador13" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ13(newValue)} name="indicador13" 
                                value={q13} id="indicador13" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ13(newValue)} name="indicador13" 
                                value={q13} id="indicador13" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ13(newValue)} name="indicador13" 
                                value={q13} id="indicador13" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ13(newValue)} name="indicador13" 
                                value={q13} id="indicador13" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ13(newValue)} name="indicador13" 
                                value={q13} id="indicador13" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ13(newValue)} name="indicador13" 
                                value={q13} id="indicador13" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ13(newValue)} name="indicador13" 
                                value={q13} id="indicador13" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ13(newValue)} name="indicador13" 
                                value={q13} id="indicador13" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ13(newValue)} name="indicador13" 
                                value={q13} id="indicador13" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ13(newValue)} name="indicador13" 
                                value={q13} id="indicador13" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ13(newValue)} name="indicador13" 
                                value={q13} id="indicador13" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ13(newValue)} name="indicador13" 
                                value={q13} id="indicador13" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ13(newValue)} name="indicador13" 
                                value={q13} id="indicador13" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ13(newValue)} name="indicador13" 
                                value={q13} id="indicador13" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ13(newValue)} name="indicador13" 
                                value={q13} id="indicador13" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ13(newValue)} name="indicador13" 
                                value={q13} id="indicador13" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ13(newValue)} name="indicador13" 
                                value={q13} id="indicador13" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ13(newValue)} name="indicador13" 
                                value={q13} id="indicador13" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ13(newValue)} name="indicador13" 
                                value={q13} id="indicador13" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ13(newValue)} name="indicador13" 
                                value={q13} id="indicador13" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ13(newValue)} name="indicador13" 
                                value={q13} id="indicador13" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ13(newValue)} name="indicador13" 
                                value={q13} id="indicador13" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ13(newValue)} name="indicador13" 
                                value={q13} id="indicador13" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ13(newValue)} name="indicador13" 
                                value={q13} id="indicador13" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ13(newValue)} name="indicador13" 
                                value={q13} id="indicador13" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ13(newValue)} name="indicador13" 
                                value={q13} id="indicador13" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ13(newValue)} name="indicador13" 
                                value={q13} id="indicador13" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ13(newValue)} name="indicador13" 
                                value={q13} id="indicador13" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ13(newValue)} name="indicador13" 
                                value={q13} id="indicador13" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">14</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Nº de evoluções realizadas (sem TN ou dieta zero)</Text>
                            </HStack>
                            <Spacer />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ14(newValue)} name="indicador14" 
                                value={q14} id="indicador14" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ14(newValue)} name="indicador14" 
                                value={q14} id="indicador14" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ14(newValue)} name="indicador14" 
                                value={q14} id="indicador14" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ14(newValue)} name="indicador14" 
                                value={q14} id="indicador14" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ14(newValue)} name="indicador14" 
                                value={q14} id="indicador14" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ14(newValue)} name="indicador14" 
                                value={q14} id="indicador14" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ14(newValue)} name="indicador14" 
                                value={q14} id="indicador14" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ14(newValue)} name="indicador14" 
                                value={q14} id="indicador14" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ14(newValue)} name="indicador14" 
                                value={q14} id="indicador14" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ14(newValue)} name="indicador14" 
                                value={q14} id="indicador14" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ14(newValue)} name="indicador14" 
                                value={q14} id="indicador14" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ14(newValue)} name="indicador14" 
                                value={q14} id="indicador14" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ14(newValue)} name="indicador14" 
                                value={q14} id="indicador14" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ14(newValue)} name="indicador14" 
                                value={q14} id="indicador14" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ14(newValue)} name="indicador14" 
                                value={q14} id="indicador14" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ14(newValue)} name="indicador14" 
                                value={q14} id="indicador14" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ14(newValue)} name="indicador14" 
                                value={q14} id="indicador14" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ14(newValue)} name="indicador14" 
                                value={q14} id="indicador14" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ14(newValue)} name="indicador14" 
                                value={q14} id="indicador14" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ14(newValue)} name="indicador14" 
                                value={q14} id="indicador14" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ14(newValue)} name="indicador14" 
                                value={q14} id="indicador14" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ14(newValue)} name="indicador14" 
                                value={q14} id="indicador14" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ14(newValue)} name="indicador14" 
                                value={q14} id="indicador14" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ14(newValue)} name="indicador14" 
                                value={q14} id="indicador14" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ14(newValue)} name="indicador14" 
                                value={q14} id="indicador14" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ14(newValue)} name="indicador14" 
                                value={q14} id="indicador14" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ14(newValue)} name="indicador14" 
                                value={q14} id="indicador14" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ14(newValue)} name="indicador14" 
                                value={q14} id="indicador14" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ14(newValue)} name="indicador14" 
                                value={q14} id="indicador14" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ14(newValue)} name="indicador14" 
                                value={q14} id="indicador14" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">15</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Nº de evoluções realizadas (com TN)</Text>
                            </HStack>
                            <Spacer />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ15(newValue)} name="indicador15" 
                                value={q15} id="indicador15" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ15(newValue)} name="indicador15" 
                                value={q15} id="indicador15" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ15(newValue)} name="indicador15" 
                                value={q15} id="indicador15" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ15(newValue)} name="indicador15" 
                                value={q15} id="indicador15" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ15(newValue)} name="indicador15" 
                                value={q15} id="indicador15" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ15(newValue)} name="indicador15" 
                                value={q15} id="indicador15" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ15(newValue)} name="indicador15" 
                                value={q15} id="indicador15" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ15(newValue)} name="indicador15" 
                                value={q15} id="indicador15" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ15(newValue)} name="indicador15" 
                                value={q15} id="indicador15" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ15(newValue)} name="indicador15" 
                                value={q15} id="indicador15" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ15(newValue)} name="indicador15" 
                                value={q15} id="indicador15" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ15(newValue)} name="indicador15" 
                                value={q15} id="indicador15" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ15(newValue)} name="indicador15" 
                                value={q15} id="indicador15" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ15(newValue)} name="indicador15" 
                                value={q15} id="indicador15" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ15(newValue)} name="indicador15" 
                                value={q15} id="indicador15" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ15(newValue)} name="indicador15" 
                                value={q15} id="indicador15" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ15(newValue)} name="indicador15" 
                                value={q15} id="indicador15" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ15(newValue)} name="indicador15" 
                                value={q15} id="indicador15" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ15(newValue)} name="indicador15" 
                                value={q15} id="indicador15" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ15(newValue)} name="indicador15" 
                                value={q15} id="indicador15" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ15(newValue)} name="indicador15" 
                                value={q15} id="indicador15" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ15(newValue)} name="indicador15" 
                                value={q15} id="indicador15" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ15(newValue)} name="indicador15" 
                                value={q15} id="indicador15" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ15(newValue)} name="indicador15" 
                                value={q15} id="indicador15" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ15(newValue)} name="indicador15" 
                                value={q15} id="indicador15" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ15(newValue)} name="indicador15" 
                                value={q15} id="indicador15" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ15(newValue)} name="indicador15" 
                                value={q15} id="indicador15" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ15(newValue)} name="indicador15" 
                                value={q15} id="indicador15" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ15(newValue)} name="indicador15" 
                                value={q15} id="indicador15" maxLength={3} keyboardType="numeric" />
                            <Input w={10} h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ15(newValue)} name="indicador15" 
                                value={q15} id="indicador15" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">16</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Nº de reavaliações (AV. Completa) em gestantes sem TN</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ16(newValue)} name="indicador16" 
                                value={q16} id="indicador16" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">17</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Nº de reavaliações (AV. Completa) em gestantes com TN</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ17(newValue)} name="indicador17" 
                                value={q17} id="indicador17" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">18</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Nº de retriagens sem TN</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ18(newValue)} name="indicador18" 
                                value={q18} id="indicador18" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">19</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Nº de retriagens com TN</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ19(newValue)} name="indicador19" 
                                value={q19} id="indicador19" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">20</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Nº pacientes identificados com risco pela retriagem</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ20(newValue)} name="indicador20" 
                                value={q20} id="indicador20" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center',
                                            flexDirection: "row",
                                            flex: 1,
                                            flexWrap: "wrap"}}>
                                <Text color="coolGray.800" w="5">21</Text>
                                <Text color="coolGray.800" w="80%" style={{fontSize: 12}}>Avaliação completa pacientes identificados com risco nutricional na retriagem</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ21(newValue)} name="indicador21" 
                                value={q21} id="indicador21" maxLength={3} keyboardType="numeric" isInvalid={inQ21}/>
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center',
                                            flexDirection: "row",
                                            flex: 1,
                                            flexWrap: "wrap"}}>
                                <Text color="coolGray.800" w="5">22</Text>
                                <Text color="coolGray.800" w="80%" style={{fontSize: 12}}>Avaliação completa pacientes sem risco (identificados pela retriagem) que mantem uso de TN</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ22(newValue)} name="indicador22" 
                                value={q22} id="indicador22" maxLength={3} keyboardType="numeric" isInvalid={inQ22}/>
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.300"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">23</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>DIAGNÓSTICO NUTRICIONAL DOS PACIENTES EM TN (PREENCHER APENAS NA PRIMEIRA AVALIAÇÃO COMPLETA)</Text>
                            </HStack>
                            <Spacer />
                            
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center',
                                            flexDirection: "row",
                                            flex: 1,
                                            flexWrap: "wrap"}}>
                                <Text color="coolGray.800" w="5">24</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Desnutrição Não grave relacionada a inanição ou não relacionada à doença</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ24(newValue)} name="indicador24" 
                                value={q24} id="indicador24" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">25</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Desnutrição Não grave relacionada à doença aguda</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ25(newValue)} name="indicador25" 
                                value={q25} id="indicador25" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">26</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Desnutrição Não grave  relacionada à doença crônica</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ26(newValue)} name="indicador26" 
                                value={q26} id="indicador26" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center',
                                                flexDirection: "row",
                                                flex: 1,
                                                flexWrap: "wrap"}}>
                                <Text color="coolGray.800" w="5">27</Text>
                                <Text color="coolGray.800" w="80%" style={{fontSize: 12}}>Desnutrição Não grave relacionada à doença crônica agudizada</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ27(newValue)} name="indicador27" 
                                value={q27} id="indicador27" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center',
                                            flexDirection: "row",
                                            flex: 1,
                                            flexWrap: "wrap"}}>
                                <Text color="coolGray.800" w="5">28</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Desnutrição Grave relacionada a inanição ou não relacionada a doença </Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ28(newValue)} name="indicador28" 
                                value={q28} id="indicador28" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">29</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Desnutrição Grave  relacionada à doença aguda </Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ29(newValue)} name="indicador29" 
                                value={q29} id="indicador29" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">30</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Desnutrição Grave  relacionada à doença crônica</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ30(newValue)} name="indicador30" 
                                value={q30} id="indicador30" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center',
                                                flexDirection: "row",
                                                flex: 1,
                                                flexWrap: "wrap"}}>
                                <Text color="coolGray.800" w="5">31</Text>
                                <Text color="coolGray.800" w="80%" style={{fontSize: 12}}>Desnutrição Grave  relacionada à doença crônica agudizada</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ31(newValue)} name="indicador31" 
                                value={q31} id="indicador31" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center',
                                            flexDirection: "row",
                                            flex: 1,
                                            flexWrap: "wrap"}}>
                                <Text color="coolGray.800" w="5">32</Text>
                                <Text color="coolGray.800" w="80%" style={{fontSize: 12}}>Alto risco para desenvolvimento de desnutrição relacionada à (etiologia da desnutrição) </Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ32(newValue)} name="indicador32" 
                                value={q32} id="indicador32" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">33</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Baixo peso</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ33(newValue)} name="indicador33" 
                                value={q33} id="indicador33" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">34</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Eutrofia</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ34(newValue)} name="indicador34" 
                                value={q34} id="indicador34" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">35</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Sobrepeso / Obesidade /Excesso de peso</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ35(newValue)} name="indicador35" 
                                value={q35} id="indicador35" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.300"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">36</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Diagnóstico Nutricional das crianças avaliadas (0 a 28 dias de vida) (P /IG) - preencher apenas na primeira avaliação</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ36(newValue)} name="indicador36" 
                                value={q36} id="indicador36" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">37</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>PIG</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ37(newValue)} name="indicador37" 
                                value={q37} id="indicador37" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">38</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>AIG</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ38(newValue)} name="indicador38" 
                                value={q38} id="indicador38" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">39</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>GIG</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ39(newValue)} name="indicador39" 
                                value={q39} id="indicador39" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.300"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">40</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Diagnóstico Nutricional das crianças avaliadas na Pediatria (P\/E &lt; 2 anos e IMC\/I &gt; 2 anos) - preencher apenas na primeira avaliação dos pacientes com Risco Nutricional</Text>
                            </HStack>
                            <Spacer />
                            
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">41</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Magreza acentuada</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ41(newValue)} name="indicador41" 
                                value={q41} id="indicador41" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">42</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Magreza</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ42(newValue)} name="indicador42" 
                                value={q42} id="indicador42" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">43</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Eutrofia</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ43(newValue)} name="indicador43" 
                                value={q43} id="indicador43" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">44</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Risco de Sobrepeso</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ44(newValue)} name="indicador44" 
                                value={q44} id="indicador44" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">45</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Sobrepeso</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ45(newValue)} name="indicador45" 
                                value={q45} id="indicador45" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">46</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Obesidade</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ46(newValue)} name="indicador46" 
                                value={q46} id="indicador46" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">47</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Obesidade grave</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ47(newValue)} name="indicador47" 
                                value={q47} id="indicador47" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.300"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">48</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>TERAPIA NUTRICIONAL (INCIDÊNCIA)</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ48(newValue)} name="indicador48" 
                                value={q48} id="indicador48" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">49</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Nº de novos de pacientes em TN (total)</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ49(newValue)} name="indicador49" 
                                value={q49} id="indicador49" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center',
                                            flexDirection: "row",
                                            flex: 1,
                                            flexWrap: "wrap"}}>
                                <Text color="coolGray.800" w="5">50</Text>
                                <Text color="coolGray.800" w="80%" style={{fontSize: 12}}>Nº de novos pacientes em TN que possuem avaliação completa (feita hoje ou anteriormente)</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ50(newValue)} name="indicador50" 
                                value={q50} id="indicador50" maxLength={3} keyboardType="numeric" isInvalid={inQ50} />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.300"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">51</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>TERAPIA NUTRICIONAL (PREVALÊNCIA)</Text>
                            </HStack>
                            <Spacer />
                            
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.200"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">52</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Nº de pacientes em TN (Total)</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ52(newValue)} name="indicador52" 
                                value={q52} id="indicador52" maxLength={3} keyboardType="numeric"  isInvalid={inQ52}/>
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">53</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Nº de pacientes com sonda</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ53(newValue)} name="indicador53" 
                                value={q53} id="indicador53" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">54</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Nº de pacientes com suplemento</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ54(newValue)} name="indicador54" 
                                value={q54} id="indicador54" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">55</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Nº de pacientes em nutrição parenteral</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ55(newValue)} name="indicador55" 
                                value={q55} id="indicador55" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">56</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Nº de pacientes com duas ou mais vias de TN</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ56(newValue)} name="indicador56" 
                                value={q56} id="indicador56" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.300"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">57</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>PACIENTE EM USO DE SONDA EXCLUSIVA (ADULTOS)</Text>
                            </HStack>
                            <Spacer />
                            
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center',
                                                flexDirection: "row",
                                                flex: 1,
                                                flexWrap: "wrap"}}>
                                <Text color="coolGray.800" w="5">58</Text>
                                <Text color="coolGray.800" w="80%" style={{fontSize: 12}}>Nº de pacientes com sonda inicada em menos de  72 horas </Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ58(newValue)} name="indicador58" 
                                value={q58} id="indicador58" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center',
                                            flexDirection: "row",
                                            flex: 1,
                                            flexWrap: "wrap"}}>
                                <Text color="coolGray.800" w="5">59</Text>
                                <Text color="coolGray.800" w="80%" style={{fontSize: 12}}>Nº de pacientes com sonda atingindo meta nutricional (calórica) - pacientes com mais de 72h de TNE</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ59(newValue)} name="indicador59" 
                                value={q59} id="indicador59" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center',
                                            flexDirection: "row",
                                            flex: 1,
                                            flexWrap: "wrap"}}>
                                <Text color="coolGray.800" w="5">60</Text>
                                <Text color="coolGray.800" w="80%" style={{fontSize: 12}}>Nº de pacientes com sonda fora da meta nutricional (calórica) - pacientes com mais de 72h de TNE</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ60(newValue)} name="indicador60" 
                                value={q60} id="indicador60" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">61</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>MOTIVOS DA NÃO ADEQUAÇÃO CALÓRICA</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ61(newValue)} name="indicador61" 
                                value={q61} id="indicador61" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">62</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Diarréia</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ62(newValue)} name="indicador62" 
                                value={q62} id="indicador62" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">63</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Procedimentos</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ63(newValue)} name="indicador63" 
                                value={q63} id="indicador63" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">64</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Instabilidade hemodinâmica</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ64(newValue)} name="indicador64" 
                                value={q64} id="indicador64" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">65</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Vômitos\/ distensão abdominal</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ65(newValue)} name="indicador65" 
                                value={q65} id="indicador65" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">66</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Saida inadvertida\/obstrução de sonda</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ66(newValue)} name="indicador66" 
                                value={q66} id="indicador66" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">67</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Erro de infusão</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ67(newValue)} name="indicador67" 
                                value={q67} id="indicador67" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">68</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Outros</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ68(newValue)} name="indicador68" 
                                value={q68} id="indicador68" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center',
                                            flexDirection: "row",
                                            flex: 1,
                                            flexWrap: "wrap"}}>
                                <Text color="coolGray.800" w="5">69</Text>
                                <Text color="coolGray.800" w="80%" style={{fontSize: 12}}>Nº de pacientes com sonda atingindo meta nutricional (proteica) - pacientes com mais de 72h de TNE</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ69(newValue)} name="indicador69" 
                                value={q69} id="indicador69" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center',
                                            flexDirection: "row",
                                            flex: 1,
                                            flexWrap: "wrap"}}>
                                <Text color="coolGray.800" w="5">70</Text>
                                <Text color="coolGray.800" w="80%" style={{fontSize: 12}}>Nº de pacientes com sonda fora da meta nutricional (proteica) - pacientes com mais de 72h de TNE</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ70(newValue)} name="indicador70" 
                                value={q70} id="indicador70" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">71</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>MOTIVOS DA NÃO ADEQUAÇÃO PROTEICA</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ71(newValue)} name="indicador71" 
                                value={q71} id="indicador71" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">72</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Diarréia</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ72(newValue)} name="indicador72" 
                                value={q72} id="indicador72" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">73</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Procedimentos</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ73(newValue)} name="indicador73" 
                                value={q73} id="indicador73" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">74</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Instabilidade hemodinâmica</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ74(newValue)} name="indicador74" 
                                value={q74} id="indicador74" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">75</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Vômitos / distensão abdominal</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ75(newValue)} name="indicador75" 
                                value={q75} id="indicador75" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">76</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Saida inadvertida /obstrução de sonda</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ76(newValue)} name="indicador76" 
                                value={q76} id="indicador76" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">77</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Erro de infusão</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ77(newValue)} name="indicador77" 
                                value={q77} id="indicador77" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">78</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Outros</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ78(newValue)} name="indicador78" 
                                value={q78} id="indicador78" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.300"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">79</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>OUTRAS ATIVIDADES</Text>
                            </HStack>
                            <Spacer />
                            
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">80</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Pareceres respondidos</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ80(newValue)} name="indicador80" 
                                value={q80} id="indicador80" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.200"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">81</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>ORIENTAÇÃO DE ALTA HOSPITALAR</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ81(newValue)} name="indicador81" 
                                value={q81} id="indicador81" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">82</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Orientação de alta hospitalar (com ou sem suplemento)</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ82(newValue)} name="indicador82" 
                                value={q82} id="indicador82" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center',
                                            flexDirection: "row",
                                            flex: 1,
                                            flexWrap: "wrap"}}>
                                <Text color="coolGray.800" w="5">83</Text>
                                <Text color="coolGray.800" w="80%" style={{fontSize: 12}}>Orientação de alta hospitalar (sonda ou parenteral que receberam alta)</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ83(newValue)} name="indicador83" 
                                value={q83} id="indicador83" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">84</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Nº de pacientes em uso de sonda ou parenteral que receberam alta</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ84(newValue)} name="indicador84" 
                                value={q84} id="indicador84" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">85</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Nº de bioimpedâncias realizadas</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ85(newValue)} name="indicador85" 
                                value={q85} id="indicador85" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">86</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Nº de exames de calorimetria realizados</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ86(newValue)} name="indicador86" 
                                value={q86} id="indicador86" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                </ScrollView>
                <Box alignItems="center" p="3">
                    <Button leftIcon={<Icon as={Ionicons} name="cloud-upload-outline" size="sm" />} size="lg" onPress={handleSubmit}>Salvar</Button>
                </Box>
            </FormControl>
            
        </View>

    );

}