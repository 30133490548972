import React,{ useEffect, useState } from "react";
import { Center, Heading, Text, VStack, FormControl, Box, Input, Link, Button, HStack, Image, StatusBar, Container, IconButton, Icon, View, FlatList, Flex, Spacer, Divider, Select, CheckIcon, ScrollView, Alert, CloseIcon, useToast, Spinner } from "native-base";
import {MaterialIcons, Ionicons} from "@expo/vector-icons";
import indicadores from "../data/indicadores";
import unidades from "../data/unidades";
import moment from "moment";
import AsyncStorageLib from "@react-native-async-storage/async-storage";

export default function EstatisticaEnf ({route, navigation})
{  
    const [dados, setDados] = useState([]);

    
    const getData = async () => {
        try {
            const value = await AsyncStorageLib.getItem('@usuario');
            const retorno = JSON.parse(value);
            
            if(value !== null) {
                setDados(retorno);
            }
        } catch(e) {
            // error reading value
        }
    }
    
    
    useEffect(() => {
        getData();
    }, []);
    const unidades_hospital = unidades.filter(e => e.id_hospital === parseInt(dados.id_hospital));
    

    let [ano, setAno] = React.useState(moment().format("YYYY"));
    let [mes, setMes] = React.useState(moment().format("M"));
    let [dia, setDia] = React.useState(moment().format("D"));
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [unidade, setUnidade] = useState([]);
    const [mensagem, setMensagem] = useState('');
    

    const [q1, setQ1] = useState('');
    const [q2, setQ2] = useState(0);
    const [q3, setQ3] = useState('');
    const [q4, setQ4] = useState('');
    const [q5, setQ5] = useState('');
    const [q6, setQ6] = useState('');
    const [q7, setQ7] = useState('');
    const [q8, setQ8] = useState('');
    const [q9, setQ9] = useState('');
    const [q10, setQ10] = useState('');
    const [q11, setQ11] = useState('');
    const [q12, setQ12] = useState('');
    const [q13, setQ13] = useState('');
    const [q14, setQ14] = useState('');
    const [q15, setQ15] = useState('');
    const [q16, setQ16] = useState('');
    const [q17, setQ17] = useState('');
    const [q18, setQ18] = useState('');
    const [q19, setQ19] = useState('');
    const [q20, setQ20] = useState('');
    const [q21, setQ21] = useState('');
    const [q22, setQ22] = useState('');
    const [q23, setQ23] = useState('');
    const [q24, setQ24] = useState('');
    const [q25, setQ25] = useState('');
    const [q26, setQ26] = useState('');
    const [q27, setQ27] = useState('');
    const [q28, setQ28] = useState('');
    const [q29, setQ29] = useState('');
    const [q30, setQ30] = useState('');
    const [q31, setQ31] = useState('');
    const [q32, setQ32] = useState('');
    const [q33, setQ33] = useState('');
    const [q34, setQ34] = useState('');
    const [q35, setQ35] = useState('');
    const [q36, setQ36] = useState('');
    const [q37, setQ37] = useState('');
    const [q38, setQ38] = useState('');
   

    const [mostrar, setMostrar] = useState(false);
    const [textoValidacao, setTextoValidacao] = useState('');

    const [inQ10, setInQ10] = useState(false);
    const [inQ11, setInQ11] = useState(false);
    const [inQ16, setInQ16] = useState(false);
    const [inQ25, setInQ25] = useState(false);
    const [inQ26, setInQ26] = useState(false);
    
    /*useEffect(() => {
        fetch('https://sisibranutro.com.br/appEstatistica/api.php?token=indicador')
            .then((response) => response.json())
            .then((json) => setData(json))
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        fetch('https://sisibranutro.com.br/appEstatistica/api.php?token=unidade&id_hospital=12')
            .then((response) => response.json())
            .then((json) => setUnidade(json))
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
    }, []);*/

    function call_others(function_name, value) {
        value = value.replace(/[^0-9]/g, '');
        eval("setQ"+function_name+"('"+value+"')");
        //console.log("Valor agora: "+eval("q"+function_name))
    }

    const handleChange = (questao, valor) => {
        call_others(questao, valor); 
    }
    const toast = useToast();

    const buscarDadosEMTN = async (id_unidade) => {
        setLoading(true);
        setMensagem('Resgatando dados da EMTN 😬');
        

        const params = {
            token: "buscar_emtn",
            id_hospital: dados.id_hospital,
            nu_ano: ano,
            nu_mes: mes,
            nu_dia: dia,
            id_unidade: id_unidade
        }
        fetch('https://sisibranutro.com.br/appEstatistica/api.php', {
            method: 'POST',
            headers: {
              //Accept: 'application/json',
              //'Content-Type': 'multipart/form-data',
              'Content-Type' : 'application/json'
            },
            body: JSON.stringify(params),
          })
            .then((response) => response.json())
            .then((json) => {
                
                json.map((objeto) => (
                    call_others(objeto.id_indicador, objeto.nu_valor)
                ))

            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
    }

    const buscarDados = async () => {
        setLoading(true);
        setMensagem('Buscando dados 😬');
        if(unidade == '')
        {
            toast.show({
                render: () => {
                  return <Box bg="danger.600" px="2" py="1" rounded="sm" mb={5}>
                          Preencha a unidade
                        </Box>;
                },
                placement: "top"
            });
            setLoading(false);
            return false;
        }

        const params = {
            token: 'buscar_enf',
            id_hospital: dados.id_hospital,
            nu_ano: ano,
            nu_mes: mes,
            nu_dia: dia,
            id_usuario: dados.id_usuario,
            id_unidade: unidade,
        }


        fetch('https://sisibranutro.com.br/appEstatistica/api.php', {
            method: 'POST',
            headers: {
              //Accept: 'application/json',
              //'Content-Type': 'multipart/form-data',
              'Content-Type' : 'application/json'
            },
            body: JSON.stringify(params),
          })
            .then((response) => response.json())
            .then((json) => {
                
                json.map((objeto) => (
                    call_others(objeto.id_indicador, objeto.nu_valor)
                ))

            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));

    }

    

    const handleSubmit = () => {
        setLoading(true);
        setMensagem('Gravando dados 🙃');
        

        if(unidade == '')
        {
            toast.show({
                render: () => {
                  return <Box bg="danger.600" px="2" py="1" rounded="sm" mb={5}>
                          Preencha a unidade
                        </Box>;
                },
                placement: "top"
            });
            setLoading(false);
            return false;
        }

        const params = {
            ano: ano,
            mes: mes,
            unidade: unidade,
            dia: dia,
            q1: q1,
            q2: q2,
            q3: q3,
            q4: q4,
            q5: q5,
            q6: q6,
            q7: q7,
            q8: q8,
            q9: q9,
            q10: q10,
            q11: q11,
            q12: q12,
            q13: q13,
            q14: q14,
            q15: q15,
            q16: q16,
            q17: q17,
            q18: q18,
            q19: q19,
            q20: q20,
            q21: q21,
            q22: q22,
            q23: q23,
            q24: q24,
            q25: q25,
            q26: q26,
            q27: q27,
            q28: q28,
            q29: q29,
            q30: q30,
            q31: q31,
            q32: q32,
            q33: q33,
            q34: q34,
            q35: q35,
            q36: q36,
            q37: q37,
            q38: q38,
            id_hospital: dados.id_hospital,
            id_usuario: dados.id_usuario,
            token: 'gravar_enf'
        }
        

        fetch('https://sisibranutro.com.br/appEstatistica/api.php', {
            method: 'POST',
            headers: {
              //Accept: 'application/json',
              //'Content-Type': 'multipart/form-data',
              'Content-Type' : 'application/json'
            },
            body: JSON.stringify(params),
          })
            .then((response) => response.json())
            .then((json) => toast.show({
                render: () => {
                  return <Box bg="emerald.500" px="2" py="1" rounded="sm" mb={5}>
                          {json.message} 😁
                        </Box>;
                },
                placement: "top"
            }))
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
    }

    const somaQ2 = () => {

        let valq3 = isNaN(parseInt(q3)) ? 0 : parseInt(q3);
        let valq4 = isNaN(parseInt(q4)) ? 0 : parseInt(q4);
        let valq5 = isNaN(parseInt(q5)) ? 0 : parseInt(q5);
        let valq6 = isNaN(parseInt(q6)) ? 0 : parseInt(q6);
        let valq7 = isNaN(parseInt(q7)) ? 0 : parseInt(q7);
        
        let total = valq3 + valq4 + valq5 + valq6 + valq7;
        
        if(!isNaN(total))
        {
            setQ2(total);
        }
        
    }

    const somaQ18 = () => {

        let valq19 = isNaN(parseInt(q19)) ? 0 : parseInt(q19);
        let valq20 = isNaN(parseInt(q20)) ? 0 : parseInt(q20);
        let valq21 = isNaN(parseInt(q21)) ? 0 : parseInt(q21);
        let valq22 = isNaN(parseInt(q22)) ? 0 : parseInt(q22);
        let valq23 = isNaN(parseInt(q23)) ? 0 : parseInt(q23);
        
        let total = valq19 + valq20 + valq21 + valq22 + valq23;
        
        if(!isNaN(total))
        {
            setQ18(total);
        }
        
    }

    const somaQ30 = () => {

        let valq31 = isNaN(parseInt(q31)) ? 0 : parseInt(q31);
        let valq32 = isNaN(parseInt(q32)) ? 0 : parseInt(q32);
        let valq33 = isNaN(parseInt(q33)) ? 0 : parseInt(q33);
        let valq34 = isNaN(parseInt(q34)) ? 0 : parseInt(q34);
        let valq35 = isNaN(parseInt(q35)) ? 0 : parseInt(q35);
        let valq36 = isNaN(parseInt(q36)) ? 0 : parseInt(q36);
        let valq37 = isNaN(parseInt(q37)) ? 0 : parseInt(q37);
        let valq38 = isNaN(parseInt(q38)) ? 0 : parseInt(q38);
        
        let total = valq31 + valq32 + valq33 + valq34 + valq35 + valq36 + valq37 + valq38;
        
        if(!isNaN(total))
        {
            setQ30(total);
        }
        
    }

    const validaQ10 = () => {
        
        let valq9 = isNaN(parseInt(q9)) ? 0 : parseInt(q9);
        let valq10 = isNaN(parseInt(q10)) ? 0 : parseInt(q10);
        
        let soma1 = valq9 + valq10;

        let valq3 = isNaN(parseInt(q3)) ? 0 : parseInt(q3);
        let valq5 = isNaN(parseInt(q5)) ? 0 : parseInt(q5);

        let soma2 = valq3 + valq5;
        
        let texto = ['⚠️ A soma das linhas 9 + 10 não pode ser maior que a soma das linhas 3 + 5'];
        if(soma1 > soma2)
        {
            setMostrar(true);   
            setInQ10(true); 
            setTextoValidacao([...textoValidacao, ...texto.filter(c => !textoValidacao.includes(c))]);    
        }
        else
        {
            var array = [...textoValidacao];
            var filter = array.filter(e => e !== texto[0]);
            setTextoValidacao(filter);
        }
        
        
    }

    const validaQ16 = () => {
        
        let valq13 = isNaN(parseInt(q13)) ? 0 : parseInt(q13);
        let valq14 = isNaN(parseInt(q14)) ? 0 : parseInt(q14);
        let valq15 = isNaN(parseInt(q15)) ? 0 : parseInt(q15);
        let valq16 = isNaN(parseInt(q16)) ? 0 : parseInt(q16);

        let soma1 = valq13 + valq14 + valq15 + valq16;

        let valq3 = isNaN(parseInt(q3)) ? 0 : parseInt(q3);
        let valq4 = isNaN(parseInt(q4)) ? 0 : parseInt(q4);
        let valq5 = isNaN(parseInt(q5)) ? 0 : parseInt(q5);

        let soma2 = valq3 + valq4 + valq5;
        
        let texto = ['⚠️ A soma das linhas 13 + 14 + 15 + 16 deve ser igual a soma das linhas 3 + 4 + 5'];
        if(soma1 != soma2)
        {
            setMostrar(true);    
            setInQ16(true);
            setTextoValidacao([...textoValidacao, ...texto.filter(c => !textoValidacao.includes(c))]);    
        }
        else
        {
            var array = [...textoValidacao];
            var filter = array.filter(e => e !== texto[0]);
            setTextoValidacao(filter);
        }
        
        
    }

    const validaQ25 = () => {
        
        let valq25 = isNaN(parseInt(q25)) ? 0 : parseInt(q25);
        

        let valq3 = isNaN(parseInt(q3)) ? 0 : parseInt(q3);
        let valq4 = isNaN(parseInt(q4)) ? 0 : parseInt(q4);
        let valq5 = isNaN(parseInt(q5)) ? 0 : parseInt(q5);

        let soma = valq3 + valq4 + valq5;
        
        let texto = ['⚠️ A linha 25 não pode ser maior que a soma das linhas 3 + 4 + 5'];
        if(valq25 > soma)
        {
            setMostrar(true);    
            setInQ25(true);
            setTextoValidacao([...textoValidacao, ...texto.filter(c => !textoValidacao.includes(c))]);    
        }
        else
        {
            var array = [...textoValidacao];
            var filter = array.filter(e => e !== texto[0]);
            setTextoValidacao(filter);
        }
    }

    const validaQ26 = () => {
        
        let valq26 = isNaN(parseInt(q26)) ? 0 : parseInt(q26);
        

        let valq3 = isNaN(parseInt(q3)) ? 0 : parseInt(q3);
        let valq4 = isNaN(parseInt(q4)) ? 0 : parseInt(q4);
        let valq5 = isNaN(parseInt(q5)) ? 0 : parseInt(q5);

        let soma = valq3 + valq4 + valq5;
        
        let texto = ['⚠️ A linha 26 não pode ser maior que a soma das linhas 3 + 4 + 5'];
        if(valq26 > soma)
        {
            setMostrar(true);   
            setInQ26(true); 
            setTextoValidacao([...textoValidacao, ...texto.filter(c => !textoValidacao.includes(c))]);    
        }
        else
        {
            var array = [...textoValidacao];
            var filter = array.filter(e => e !== texto[0]);
            setTextoValidacao(filter);
        }
    }


    

    useEffect(() => {
        //console.log("Valor de q2:"+q2);    
    }, [q2]);

   

    useEffect(() => {
        somaQ2();
    }, [q3, q4, q5, q6, q7]);

   

    useEffect(() => {
        somaQ18();
    }, [q19, q20, q21, q22, q23]);

    useEffect(() => {
        validaQ10();
    }, [q3, q5, q9, q10]);

    useEffect(() => {
        validaQ16();
    }, [q3,q4,q5,q13,q14,q15,q16]);

    useEffect(() => {
        validaQ25();
    }, [q3,q4,q5,q25]);

    useEffect(() => {
        validaQ26();
    }, [q3,q4,q5,q26]);

    useEffect(() => {
        somaQ30();
    }, [q31,q32,q33,q34,q35,q36,q37,q38]);

    useEffect(() => {
        if(textoValidacao.length == 0)
        {
            setMostrar(false);
            setInQ10(false);
            setInQ11(false);
            setInQ16(false);
            setInQ25(false);
            setInQ26(false);
        }
    }, [textoValidacao]);

    
    return (
        <View bgColor="coolGray.200" flex={1}>
            <StatusBar barStyle="dark-content" />
            
            <HStack space={2} p="3" alignSelf={"flex-end"}>
                <Text alignSelf={"center"}>Olá, {dados.ds_nome}!</Text>
                <IconButton icon={<Icon as={MaterialIcons} name="arrow-back" size="md" />} onPress={() => navigation.navigate('Home')} />
            </HStack>
               
            <HStack>
                <Box w={"90%"} pt={2} px={2}>
                    { mostrar && 
                        <Alert status="error">
                            <VStack space={1} alignItems="center" >
                                <ScrollView h={50}>
                                    
                                    <Box _text={{
                                    textAlign: "left"
                                }} _dark={{
                                    _text: {
                                    color: "coolGray.600"
                                    }
                                }}>
                                    { textoValidacao }
                                    </Box>
                                </ScrollView>
                            </VStack>
                        </Alert>
                    }
                    {
                        isLoading && <Box alignItems={"center"}><Spinner accessibilityLabel="Carregando" /><Text>{mensagem}...</Text></Box>
                    }
                </Box>
            </HStack>
            <FormControl flex={1}>
                <HStack space={1} alignItems="flex-start" p="2" w="100%">
                    <Select accessibilityLabel="Ano" placeholder="Ano" selectedValue={ano} defaultValue={ano} onValueChange={itemValue => setAno(itemValue)} w="80px" id="ano" bgColor={"warmGray.100"}>
                        <Select.Item label="2021" value="2021" />
                        <Select.Item label="2022" value="2022" />
                    </Select>
                    <Select accessibilityLabel="Mes" placeholder="Mes" w="90px" selectedValue={mes} onValueChange={itemValue => setMes(itemValue)} id="mes" bgColor={"warmGray.100"}>
                        <Select.Item label="Janeiro" value="1" />
                        <Select.Item label="Fevereiro" value="2" />
                        <Select.Item label="Março" value="3" />
                        <Select.Item label="Abril" value="4" />
                        <Select.Item label="Maio" value="5" />
                        <Select.Item label="Junho" value="6" />
                        <Select.Item label="Julho" value="7" />
                        <Select.Item label="Agosto" value="8" />
                        <Select.Item label="Setembro" value="9" />
                        <Select.Item label="Outubro" value="10" />
                        <Select.Item label="Novembro" value="11" />
                        <Select.Item label="Dezembro" value="12" />
                    </Select>
                    <Select accessibilityLabel="Unidade" placeholder="Unidade" width="100px" onValueChange={itemValue => {setUnidade(itemValue); buscarDadosEMTN(itemValue);}} selectedValue={unidade} id="unidade" bgColor={"warmGray.100"}>
                        {
                            
                            unidades_hospital.map((option) => (
                                <Select.Item label={option.unidade} value={option.id.toString()} key={option.id} />
                            ))
                        }
                    </Select>
                    <Select accessibilityLabel="Dia" placeholder="Dia" w="80px" selectedValue={dia} onValueChange={itemValue => setDia(itemValue)} id="dia" bgColor={"warmGray.100"}>
                        <Select.Item label="1" value="1" />
                        <Select.Item label="2" value="2" />
                        <Select.Item label="3" value="3" />
                        <Select.Item label="4" value="4" />
                        <Select.Item label="5" value="5" />
                        <Select.Item label="6" value="6" />
                        <Select.Item label="7" value="7" />
                        <Select.Item label="8" value="8" />
                        <Select.Item label="9" value="9" />
                        <Select.Item label="10" value="10" />
                        <Select.Item label="11" value="11" />
                        <Select.Item label="12" value="12" />
                        <Select.Item label="13" value="13" />
                        <Select.Item label="14" value="14" />
                        <Select.Item label="15" value="15" />
                        <Select.Item label="16" value="16" />
                        <Select.Item label="17" value="17" />
                        <Select.Item label="18" value="18" />
                        <Select.Item label="19" value="19" />
                        <Select.Item label="20" value="20" />
                        <Select.Item label="21" value="21" />
                        <Select.Item label="22" value="22" />
                        <Select.Item label="23" value="23" />
                        <Select.Item label="24" value="24" />
                        <Select.Item label="25" value="25" />
                        <Select.Item label="26" value="26" />
                        <Select.Item label="27" value="27" />
                        <Select.Item label="28" value="28" />
                        <Select.Item label="29" value="29" />
                        <Select.Item label="30" value="30" />
                        <Select.Item label="31" value="31" />
                    </Select>
                    <Button leftIcon={<Icon as={Ionicons} name="search" size="sm" />} size="sm" name="buscar" onPress={() => buscarDados()} mt={1}></Button>
                    
                </HStack>
                
                <ScrollView flex={1}>
                    
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.300"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">1</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>ACOMPANHAMENTO (Prevalência)</Text>
                            </HStack>
                            <Spacer />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.200"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">2</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>PACIENTES</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} name="indicador2" id="indicador2" value={q2.toString()} isReadOnly />
                        </Flex>
                    </Box>

                    
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">3</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>DIETA ENTERAL</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => {setQ3(newValue)}}  name="indicador3" 
                                 id="indicador3" maxLength={3} keyboardType="numeric" value={q3} />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">4</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>DIETA PARENTERAL</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ4(newValue)}  name="indicador4" 
                                 id="indicador4" maxLength={3} keyboardType="numeric" value={q4} />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">5</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>DUPLA VIA (TNE + TNP)</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ5(newValue)} name="indicador5" 
                                value={q5} id="indicador5" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">6</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>ZERO</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ6(newValue)} name="indicador6" 
                                value={q6} id="indicador6" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">7</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>VIA ORAL ACOMPANHADO PELA EMTN</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ7(newValue)} name="indicador7" 
                                value={q7} id="indicador7" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.300"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">8</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>INTERCORRÊNCIAS DO TGI EM PACIENTES COM SONDA (Prevalência)</Text>
                            </HStack>
                            <Spacer />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">9</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>DIARREIA</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ9(newValue)} name="indicador9" 
                                value={q9} id="indicador9" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">10</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>OBSTIPAÇÃO</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ10(newValue)} name="indicador10" 
                                value={q10} id="indicador10" maxLength={3} keyboardType="numeric" isInvalid={inQ10} />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">11</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>DISTENSÃO ABDOMINAL</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ11(newValue)} name="indicador11" 
                                value={q11} id="indicador11" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.300"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">12</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>VOLUME INFUNDIDO</Text>
                            </HStack>
                            <Spacer />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">13</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>VOL. INF. CONFORME AO PRESCRITO (&gt;80%)</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ13(newValue)} name="indicador13" 
                                value={q13} id="indicador13" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">14</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>VOL. INF. NÃO CONFORME AO PRESCRITO (71 a 80%)</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ14(newValue)} name="indicador14" 
                                value={q14} id="indicador14" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">15</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>VOL. INF. NÃO CONFORME AO PRESCRITO (51 a 70%)</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ15(newValue)} name="indicador15" 
                                value={q15} id="indicador15" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">16</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>VOL. INF. NÃO CONFORME AO PRESCRITO (&lt;=50%)</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ16(newValue)} name="indicador16" 
                                value={q16} id="indicador16" maxLength={3} keyboardType="numeric" isInvalid={inQ16} />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.300"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">17</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>INTERCORRÊNCIAS COM A TNE (Prevalência)</Text>
                            </HStack>
                            <Spacer />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.200"}>
                        <Flex direction="row" >
                        <HStack alignItems="flex-start" style={{textAlignVertical: 'center',
                                            flexDirection: "row",
                                            flex: 1,
                                            flexWrap: "wrap"}}>
                                <Text color="coolGray.800" w="5">18</Text>
                                <Text color="coolGray.800" w="80%" style={{fontSize: 12}}>SUSPENSÃO / NÃO INFUSÃO DA TN (TNE / TNP OU AMBAS)</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ18(newValue)} name="indicador18" 
                                value={q18} id="indicador18" maxLength={3} keyboardType="numeric" isReadOnly />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">19</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>PAUSA PARA PROCEDIMENTO</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ19(newValue)} name="indicador19" 
                                value={q19} id="indicador19" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">20</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>INSTABILIDADE CLÍNICA</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ20(newValue)} name="indicador20" 
                                value={q20} id="indicador20" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">21</Text>
                                <Text color="coolGray.800" w="80%" style={{fontSize: 12}}>INTERCORRÊNCIAS GASTROINTESTINAIS</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ21(newValue)} name="indicador21" 
                                value={q21} id="indicador21" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">22</Text>
                                <Text color="coolGray.800" w="80%" style={{fontSize: 12}}>PERDA DE DISPOSITIVOS</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ22(newValue)} name="indicador22" 
                                value={q22} id="indicador22" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">23</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>OUTROS</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ23(newValue)} name="indicador23" 
                                value={q23} id="indicador23" maxLength={3} keyboardType="numeric" />
                            
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.300"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">24</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>INTERCORRÊNCIAS CLÍNICAS (TNE, TNP OU AMBAS)</Text>
                            </HStack>
                            <Spacer />
                            
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">25</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>PREVALÊNCIA DE HIPERGLICEMIA</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ25(newValue)} name="indicador25" 
                                value={q25} id="indicador25" maxLength={3} keyboardType="numeric" isInvalid={inQ25} />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">26</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>PREVALÊNCIA DE HIPOGLICEMIA</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ26(newValue)} name="indicador26" 
                                value={q26} id="indicador26" maxLength={3} keyboardType="numeric" isInvalid={inQ26} />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                        <HStack alignItems="flex-start" style={{textAlignVertical: 'center',
                                            flexDirection: "row",
                                            flex: 1,
                                            flexWrap: "wrap"}}>
                                <Text color="coolGray.800" w="5">27</Text>
                                <Text color="coolGray.800"  w="80%" style={{fontSize: 12}}>INCIDÊNCIA DE INFECÇÃO DE CVC EM PACIENTES COM TNP</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ27(newValue)} name="indicador27" 
                                value={q27} id="indicador27" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.300"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">28</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>OUTROS (TNE, TNP OU AMBAS) </Text>
                            </HStack>
                            <Spacer />
                            
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">29</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>RECUPERAÇÃO DA VIA ORAL </Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ29(newValue)} name="indicador29" 
                                value={q29} id="indicador29" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.200"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">30</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Nº DE NOTIFICAÇÕES EFETUADAS</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ30(newValue)} name="indicador30" 
                                value={q30} id="indicador29" maxLength={3} keyboardType="numeric" isReadOnly/>
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">31</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Cabeceira &lt;30º</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ31(newValue)} name="indicador31" 
                                value={q31} id="indicador31" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">32</Text>
                                <Text color="coolGray.800" w="80%" style={{fontSize: 12}}>Saída inadvertida de sonda </Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ32(newValue)} name="indicador32" 
                                value={q32} id="indicador32" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">33</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Perda de acesso parenteral</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ33(newValue)} name="indicador33" 
                                value={q33} id="indicador33" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">34</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Obstrução de sonda</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ34(newValue)} name="indicador34" 
                                value={q34} id="indicador34" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">35</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Erro de registro no balanço hídrico</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ35(newValue)} name="indicador35" 
                                value={q35} id="indicador35" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">36</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Erro de vazão</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ36(newValue)} name="indicador36" 
                                value={q36} id="indicador36" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">37</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Outros</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ37(newValue)} name="indicador37" 
                                value={q37} id="indicador37" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    <Box borderBottomWidth="1" borderColor="blueGray.500" pl="2" pr="2" py="2" bgColor={"blueGray.100"}>
                        <Flex direction="row" >
                            <HStack alignItems="flex-start" style={{textAlignVertical: 'center'}}>
                                <Text color="coolGray.800" w="5">38</Text>
                                <Text color="coolGray.800" noOfLines={2} w="80%" style={{fontSize: 12}}>Nº DE NOTIFICAÇÕES RECEBIDAS</Text>
                            </HStack>
                            <Spacer />
                            <Input w="15%" h="30" alignSelf="flex-end" bgColor="coolGray.100" size="sm" variant="outline" p="1.5" isFullWidth={true} onChangeText={newValue => setQ38(newValue)} name="indicador38" 
                                value={q38} id="indicador38" maxLength={3} keyboardType="numeric" />
                        </Flex>
                    </Box>
                    
                </ScrollView>
                <Box alignItems="center" p="3">
                    <Button leftIcon={<Icon as={Ionicons} name="cloud-upload-outline" size="sm" />} size="lg" onPress={handleSubmit}>Salvar</Button>
                </Box>
            </FormControl>
            
        </View>

    );

}