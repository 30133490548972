import React,{ useEffect, useState } from "react";
import { Center, Heading, Text, VStack, FormControl, Box, Input, Link, Button, HStack, Image, StatusBar, Container, IconButton, Icon, View, FlatList, Flex, Spacer, Divider, Select, CheckIcon, ScrollView, Alert, CloseIcon, useToast, Spinner, Radio, Checkbox, Modal } from "native-base";
import {MaterialIcons, Ionicons, FontAwesome5} from "@expo/vector-icons";


import unidades from "../data/unidades";


export default function EstadoNutricional ({navigation})
{  
    const [isLoading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalVisibleAlta, setModalVisibleAlta] = useState(false);
    const [modalVisibleHistorico, setModalVisibleHistorico] = useState(false);
    
    const [paciente, setPaciente] = useState([]);
    const [atendimento, setAtendimento] = useState('');
    const [nome, setNome] = useState('');
    const [id, setId] = useState(0);
    const [sexo, setSexo] = useState('M');
    const [unidade, setUnidade] = useState('');
    const [dataNRS, setDataNRS] = useState('');
    const [nrs, setNrs] = useState('');
    const [dataDiag, setDataDiag] = useState('');
    const [diagNut, setDiagNut] = useState('');
    const [dataAlta, setDataAlta] = useState('');
    const [motivoAlta, setMotivoAlta] = useState('');
    const [orientado, setOrientado] = useState('');
    const [idAlta, setIdAlta] = useState('');
    
    
    const toast = useToast();

    const limparDados = () => {
        setAtendimento('');
        setNome('');
        setSexo('M');
        setUnidade('');
        setDataNRS('');
        setNrs('');
        setDataDiag('');
        setDiagNut('');
    }

    const limparDadosAlta = () => {
        setDataAlta('');
        setMotivoAlta('');
        setOrientado('');
    }

    const handleSalvarAlta = () => {
        setLoading(true);
        paciente[idAlta].dataAlta = dataAlta;
        paciente[idAlta].motivoAlta = motivoAlta;
        paciente[idAlta].orientado = orientado;

        
        limparDadosAlta();
        setModalVisibleAlta(false);
        setLoading(false);

        
    }

    const handleSalvar = () => {
        setLoading(true);
        const dados = new FormData();

        if(atendimento == '')
        {
            toast.show({
                render: () => {
                  return <Box bg="danger.600" px="2" py="1" rounded="sm" mb={5}>
                          Preencha o atendimento
                        </Box>;
                },
                placement: "top"
            });
            setLoading(false);
            return false;
        }

        if(nome == '')
        {
            toast.show({
                render: () => {
                  return <Box bg="danger.600" px="2" py="1" rounded="sm" mb={5}>
                          Preencha o nome
                        </Box>;
                },
                placement: "top"
            });
            setLoading(false);
            return false;
        }
        
        
        setId(id+1);
        const novoArray = [...paciente, {
            id: id,
            atendimento: atendimento,
            nome: nome,
            sexo: sexo,
            unidade: unidade,
            dataNRS: dataNRS,
            nrs: nrs,
            dataDiag: dataDiag,
            diagNut: diagNut
        }
        ];
        

        setPaciente(novoArray);
        
        setLoading(false);
        setModalVisible(false);
        limparDados();
        console.log(paciente);

        /*dados.append('ano', ano);
        dados.append('mes', mes);
        dados.append('unidade', unidade);
        dados.append('dia', dia);
        
        dados.append('id_hospital', 12);
        dados.append('id_usuario', 1);

        fetch('https://sisibranutro.com.br/appEstatistica/api.php?token=gravar', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'multipart/form-data',
            },
            body: dados,
          })
            .then((response) => response.json())
            .then((json) => toast.show({
                render: () => {
                  return <Box bg="emerald.500" px="2" py="1" rounded="sm" mb={5}>
                          {json.message}
                        </Box>;
                },
                placement: "top"
            }))
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));*/
    }

    const excluirPaciente = (id) => {
        paciente.splice(paciente.findIndex(function(i){
            return i.id === id;
        }), 1);
        setPaciente([...paciente]);
    }

    const buscarDados = async () => {
        setLoading(true);
        if(unidade == '')
        {
            toast.show({
                render: () => {
                  return <Box bg="danger.600" px="2" py="1" rounded="sm" mb={5}>
                          Preencha a unidade
                        </Box>;
                },
                placement: "top"
            });
            setLoading(false);
            return false;
        }
        fetch('https://sisibranutro.com.br/appEstatistica/api.php?token=buscar&id_hospital=12&nu_ano='+ano+'&nu_mes='+mes+'&nu_dia='+dia+'&id_unidade='+unidade)
            .then((response) => response.json())
            .then((json) => {
                
                json.map((objeto) => (
                    call_others(objeto.id_indicador, objeto.nu_valor)
                ))

            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));

    }

    return (
        <View bgColor="coolGray.200" flex={1}>
            <StatusBar barStyle="light-content" />
            <Box safeAreaTop bgColor="coolGray.200" alignItems="flex-end">
                <HStack space={2} p="3">
                    {isLoading && <Box><Spinner accessibilityLabel="Carregando" /><Text>Carregando...</Text></Box>}
                    <IconButton icon={<Icon as={MaterialIcons} name="arrow-back" size="md" />} onPress={() => navigation.navigate('Home')} />
                </HStack>
            </Box>
            <FormControl flex={1}>
                <Flex direction="row">
                    <HStack space={1} alignItems="flex-start" p="2">
                        <Button leftIcon={<Icon as={FontAwesome5} name="search" size="md" />} size="lg" name="buscar" onPress={() => buscarDados()}></Button>
                        <Button leftIcon={<Icon as={FontAwesome5} name="user-plus" size="md" />} size="lg" name="adicionar" onPress={() => setModalVisible(true)}></Button>
                    </HStack>
                    <VStack alignSelf="flex-end" px={25}>
                        <Radio.Group defaultValue="1" size="sm" name="filtro" accessibilityLabel="Escolha uma opção">
                            <Radio value="1" my={1}>
                                <Text>Todos</Text>
                            </Radio>
                            <Radio value="2" my={1}>
                                <Text>Reavaliações pendentes</Text>
                            </Radio>
                            <Radio value="3" my={1}>
                                <Text>Notificações pendentes</Text>
                            </Radio>
                            <Radio value="4" my={1}>
                                <Text>Orientados</Text>
                            </Radio>
                        </Radio.Group>
                    </VStack>
                </Flex>
                <View flex={1} py={5} borderRadius={50}>
                    <FlatList data={paciente} renderItem = {({item}) => 
                        <HStack bg="gray.200" space={3} borderBottomWidth={0.5} borderBottomColor="gray.300">
                            <Box w="40%">
                                <Text adjustsFontSizeToFit px={5} onPress={() => {setModalVisibleHistorico(true)}}>{item.nome}</Text>
                            </Box>
                            <Box w="10%">
                                <Text adjustsFontSizeToFit>{item.atendimento}</Text>
                            </Box>
                            <Box w="5%" py={2}>
                                
                            </Box>
                            <Box w="5%" py={2}>
                                <Icon as={FontAwesome5} name="bed" size="sm" color="blue.400" onPress={() => {setModalVisibleAlta(true); setIdAlta(item.id)}}/>
                            </Box>
                            <Box w="5%" py={2}>
                                <Icon as={FontAwesome5} name="clipboard-list" size="sm" />
                            </Box>
                            <Box w="5%" py={2}>
                                <Icon as={FontAwesome5} name="trash-alt" size="sm" color="danger.700" onPress={() => {excluirPaciente(item.id)}} />
                            </Box>
                            <Box w="5%" py={2}>
                                <Checkbox value={item.id} accessibilityLabel="Orientação de alta" isChecked={paciente[item.id] ? paciente[item.id].orientado == true ? true : false : false} isReadOnly={true} />
                            </Box>
                        </HStack>
                    } keyExtractor={item => item.id.toString()} ListHeaderComponent={
                                <HStack bg="gray.100" space={3}>
                                    <Box w="40%" py={2}>
                                        <Text px={5} fontWeight="bold">Paciente</Text>
                                    </Box>
                                    <Box w="10%" py={2} >
                                        <Text fontWeight="bold">At.</Text>
                                    </Box>
                                    <Box w="5%" py={2}>
                                        <Icon as={FontAwesome5} name="info-circle" size="sm" color="warning.500" />
                                    </Box>
                                    <Box w="5%" py={2}>
                                        <Icon as={FontAwesome5} name="bed" size="sm" color="blue.400"/>
                                    </Box>
                                    <Box w="5%" py={2}>
                                        <Icon as={FontAwesome5} name="clipboard-list" size="sm" />
                                    </Box>
                                    <Box w="5%" py={2}>
                                        <Icon as={FontAwesome5} name="trash-alt" size="sm" color="danger.700" />
                                    </Box>
                                    <Box w="5%" py={2}>
                                        <Icon as={FontAwesome5} name="check-square" size="sm" color="success.700" />
                                    </Box>
                                </HStack>
                        } ListEmptyComponent={
                            <HStack bg="gray.100" space={3}>
                                <Box w="100%">
                                    <Text textAlign="center" color="red.600" py={5} fontSize={16}>Não existem pacientes cadastrados</Text>
                                </Box>
                            </HStack>
                        } />
                </View>
            </FormControl>
            
            <Modal isOpen={modalVisible} onClose={setModalVisible} size="full" alignItems="center">
                <Modal.Content>
                    <Modal.CloseButton />
                    <Modal.Header>Adicionar Paciente </Modal.Header>
                    <Modal.Body>
                        <Box>
                            <HStack space={3}>
                                <Input placeholder="Atendimento" w="30%" size="md" value={atendimento} onChangeText={newValue => setAtendimento(newValue)}/>
                                <Input placeholder="Nome" w="70%" size="md" value={nome} onChangeText={newValue => setNome(newValue)}/>
                            </HStack>
                            <HStack space={3}>
                                <VStack w="30%">
                                    <FormControl.Label>Sexo</FormControl.Label>
                                    <Radio.Group defaultValue="M" name="sexo" size="sm" value={sexo} onChange={newValue => setSexo(newValue)} accessibilityLabel="sexo">
                                        <Radio value="M">Masculino</Radio>
                                        <Radio value="F">Feminino</Radio>
                                    </Radio.Group>
                                </VStack>
                                <VStack w="70%">
                                    <FormControl.Label>Unidade de internação</FormControl.Label>
                                    <Select accessibilityLabel="Unidade" placeholder="Unidade" onValueChange={itemValue => setUnidade(itemValue)} selectedValue={unidade} id="unidade">
                                        {
                                            unidades.map((option) => (
                                                <Select.Item label={option.unidade} value={option.id.toString()} key={option.id} />
                                            ))
                                        }
                                    </Select>
                                </VStack>
                            </HStack>
                            <HStack space={3}>
                                <VStack w="30%">
                                    <FormControl.Label>Data da NRS</FormControl.Label>
                                    <Input placeholder="__/__/____" size="md" value={dataNRS} onChangeText={newValue => setDataNRS(newValue)} />
                                </VStack>
                                <VStack w="70%">
                                    <FormControl.Label>NRS</FormControl.Label>
                                    <Select selectedValue={nrs} onValueChange={itemValue => setNrs(itemValue)} accessibilityLabel="NRS" placeholder="NRS">
                                        <Select.Item value="1" label="Com risco" />
                                        <Select.Item value="2" label="Sem risco" />
                                    </Select>
                                </VStack>
                            </HStack>
                            <HStack space={3}>
                                <VStack w="30%">
                                    <FormControl.Label>Data Diag.</FormControl.Label>
                                    <Input placeholder="__/__/____" size="md" value={dataDiag} onChangeText={newValue => setDataDiag(newValue)} />
                                </VStack>
                                <VStack w="70%">
                                    <FormControl.Label>Diagnóstico Nutricional</FormControl.Label>
                                    <Select selectedValue={diagNut} onValueChange={itemValue => setDiagNut(itemValue)} accessibilityLabel="Diagnóstico Nutricional" placeholder="Diagnóstico Nutricional">
                                        <Select.Item value="1" label="Não desnutrido" />
                                        <Select.Item value="2" label="Desnutrido não grave" />
                                        <Select.Item value="3" label="Desnutrido grave" />
                                    </Select>
                                </VStack>
                            </HStack>
                        </Box>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button.Group variant="ghost" space={2}>
                            <Button onPress={() => {handleSalvar()}}>Salvar</Button>
                            <Button onPress={() => {
                                setModalVisible(!modalVisible);
                                limparDados();
                            }} colorScheme="secondary">
                                Fechar
                            </Button>
                        </Button.Group>
                    </Modal.Footer>
                </Modal.Content>
            </Modal>
            <Modal isOpen={modalVisibleAlta} onClose={setModalVisibleAlta} size="lg" alignItems="center">
                <Modal.Content>
                    <Modal.CloseButton />
                    <Modal.Header>Alta de Paciente { paciente[idAlta] ? "Atendimento: "+paciente[idAlta].atendimento+" - "+paciente[idAlta].nome : ''}</Modal.Header>
                    <Modal.Body>
                        <Box>
                            
                            <HStack space={3}>
                                
                                <VStack w="30%">
                                    <FormControl.Label>Data da Alta</FormControl.Label>
                                    <Input placeholder="__/__/____" size="md" value={dataAlta} onChangeText={newValue => setDataAlta(newValue)} />
                                </VStack>
                                <VStack w="40%">
                                    <FormControl.Label>Motivo Alta</FormControl.Label>
                                    <Select selectedValue={motivoAlta} onValueChange={itemValue => setMotivoAlta(itemValue)} accessibilityLabel="Motivo Alta" placeholder="Motivo Alta">
                                        <Select.Item value="1" label="Domicílio" />
                                        <Select.Item value="2" label="Transferência" />
                                        <Select.Item value="3" label="Home Care" />
                                        <Select.Item value="4" label="Óbito" />
                                    </Select>
                                </VStack>
                                <VStack w="30%">
                                    <FormControl.Label>&nbsp;</FormControl.Label>
                                    <Checkbox value="1" size="md" onChange={itemValue => {setOrientado(itemValue)}} isChecked={paciente[idAlta] ? paciente[idAlta].orientado ? true : false : false}>Orientado</Checkbox>
                                </VStack>
                            </HStack>
                            
                        </Box>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button.Group variant="ghost" space={2}>
                            <Button onPress={() => {handleSalvarAlta()}}>Salvar</Button>
                            <Button onPress={() => {
                                setModalVisibleAlta(!modalVisibleAlta);
                            }} colorScheme="secondary">
                                Fechar
                            </Button>
                        </Button.Group>
                    </Modal.Footer>
                </Modal.Content>
            </Modal>

            <Modal isOpen={modalVisibleHistorico} onClose={setModalVisibleHistorico} size="full" alignItems="center">
                <Modal.Content>
                    <Modal.CloseButton />
                    <Modal.Header>Histórico de diagnóstico</Modal.Header>
                    <Modal.Body>
                        <Box>
                            <HStack space={10}>
                                <Text>Histórico</Text>
                                <Text>Notificações</Text>
                                <Text>Observações</Text>
                                <Text>Demonstrativo</Text>
                            </HStack>
                        </Box>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button.Group variant="ghost" space={2}>
                            <Button onPress={() => {handleSalvarAlta()}}>Salvar</Button>
                            <Button onPress={() => {
                                setModalVisibleHistorico(!modalVisibleHistorico);
                            }} colorScheme="secondary">
                                Fechar
                            </Button>
                        </Button.Group>
                    </Modal.Footer>
                </Modal.Content>
            </Modal>
        </View>
    );

}