
    const enfermeiras = [
        {
            ds_login: "pabline.novais"
        },
        {
            ds_login: "bruna.ferrreira"
        },
        {
            ds_login: "rosana.carvalho"
        },
        {
            ds_login: "fernanda.totti"
        },
        {
            ds_login: "amanda.mendes"
        },
        {
            ds_login: "mayara.cabral"
        },
        {
            ds_login: "juliana.dourado"
        },
        {
            ds_login: "aline.araujo"
        },
        {
            ds_login: "patricia.rodrigues"
        },
        {
            ds_login: "lucilia.vasconcelos"
        },
        {
            ds_login: "giselle.uzuelli"
        }
        
        
     
        
        
    ];
export default enfermeiras;
    