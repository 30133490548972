import React from "react";
import {
  NativeBaseProvider
} from "native-base";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";

import Login from "./screens/Login";
import Home from "./screens/Home";
import Estatistica from "./screens/Estatistica";
import EstatisticaEnf from "./screens/EstatisticaEnf";
import EstadoNutricional from "./screens/EstadoNutricional";
import Evolucao from "./screens/Evolucao";
import RevisitaPuerpera from "./screens/RevisitaPuerpera";
import AvaliacaoCompleta from "./screens/AvaliacaoCompleta";
import RevisitaLactente from "./screens/RevisitaLactente";
import RevisitaComRisco from "./screens/RevisitaComRisco";
import RevisitaSemRisco from "./screens/RevisitaSemRisco";



const Stack = createNativeStackNavigator();



export default function App() {
	return (
		<NativeBaseProvider>
			<NavigationContainer>
				<Stack.Navigator initialRouteName="Login">
					<Stack.Screen name="Login" component={Login} options={{ headerShown: false, title: 'Estatística - Tela de Login'}} />
					<Stack.Screen name="Home" component={Home} options={{headerShown: false, title: 'Estatística'}} />
					<Stack.Screen name="Estatistica" component={Estatistica} options={{headerShown: false, title: 'Estatística Nutrição'}} />
					<Stack.Screen name="EstatisticaEnf" component={EstatisticaEnf} options={{headerShown: false, title: 'Estatística Enfermagem'}} />
					<Stack.Screen name="EstadoNutricional" component={EstadoNutricional} options={{headerShown: false, title: 'Estado Nutricional'}} />
					<Stack.Screen name="Evolucao" component={Evolucao} options={{headerShown: false, title: 'Evolução'}} />
					<Stack.Screen name="RevisitaPuerpera" component={RevisitaPuerpera} options={{headerShown: false, title: 'Revisita Puérpera'}} />
					<Stack.Screen name="AvaliacaoCompleta" component={AvaliacaoCompleta} options={{headerShown: false, title: 'Avaliação Completa'}} />
					<Stack.Screen name="RevisitaLactente" component={RevisitaLactente} options={{headerShown: false, title: 'Revisita Lactente'}} />
					<Stack.Screen name="RevisitaComRisco" component={RevisitaComRisco} options={{headerShown: false, title: 'Revisita Com Risco e TN'}} />
					<Stack.Screen name="RevisitaSemRisco" component={RevisitaSemRisco} options={{headerShown: false, title: 'Revisita Sem Risco e TN'}} />
				</Stack.Navigator>
			</NavigationContainer>
		</NativeBaseProvider>
	);
}

