import React, { useState,useEffect } from "react";
import { Center, Heading, Text, VStack, FormControl, Box, Input, Link, Button, HStack, Image, StatusBar, Container, IconButton, Icon, View } from "native-base";
import {MaterialIcons} from "@expo/vector-icons";
import enfermeiras from "../data/enfermeiras";
import AsyncStorageLib from "@react-native-async-storage/async-storage";


export default function Home ({route, navigation})
{  
    const [dados, setDados] = useState([]);

    
    const getData = async () => {
        try {
            const value = await AsyncStorageLib.getItem('@usuario');
            const retorno = JSON.parse(value);
            
            if(value !== null) {
                setDados(retorno);
            }
        } catch(e) {
            // error reading value
        }
    }
    
    
    useEffect(() => {
        getData();
    }, []);

    const handleSair = async () => {
        try {
            await AsyncStorageLib.clear()
            navigation.navigate('Login')
        } catch (e) {
            console.log(e)  
        }
    }
    
    var result = enfermeiras.filter(obj => {
        
        return obj.ds_login === dados.ds_usuario
    })

    console.log(result.length)
    return (
        <View flex={1}>
            <StatusBar barStyle="dark-content" />
            <Box safeAreaTop bgColor="coolGray.200" alignItems="flex-end">
                <HStack space={2} p="3">
                    <Text alignSelf={"center"}>Olá, {dados.ds_nome}!</Text>
                    <IconButton icon={<Icon as={MaterialIcons} name="exit-to-app" size="md" />} onPress={() => handleSair()} />
                </HStack>
            </Box>
            <Center bgColor="coolGray.200" flex={1}>
                <Box>
                    <Image source={require('../assets/logo.png')} alt="logo" w="400" h="150" resizeMode="contain" alignSelf="center" marginTop={-50} mb={10} />
                </Box>
                <HStack justifyContent="center" space={2} w="90%">
                    <Center h="40" w="45%" bg="blueGray.50" rounded="md" borderColor="coolGray.300" borderWidth="1" shadow={3}>
                        <IconButton icon={<Icon as={MaterialIcons} name="bar-chart" size="2xl" color="black" />} onPress={() => navigation.navigate('Estatistica')} />
                        <Text textAlign="center">Estatística Nutrição</Text>
                    </Center>
                    { (result.length == 1 || dados.id_perfil == '1' || dados.id_perfil == '8' || dados.id_perfil == '9')  &&
                        <Center h="40" w="45%" bg="blueGray.50" rounded="md" borderColor="coolGray.300" borderWidth="1" shadow={3}>
                            <IconButton icon={<Icon as={MaterialIcons} name="bar-chart" size="2xl" color="black" />} onPress={() => navigation.navigate('EstatisticaEnf')} />
                            <Text textAlign="center">Estatística Enfermagem</Text>
                        </Center>
                    }
                    
                    
                    
                </HStack>
                {
                    dados.id_perfil == 1 &&
                    <HStack justifyContent="center" space={2} w="90%" pt={2}>
                        <Center h="40" w="45%" bg="blueGray.50" rounded="md" borderColor="coolGray.300" borderWidth="1" shadow={3}>
                            <IconButton icon={<Icon as={MaterialIcons} name="show-chart" size="2xl" color="black" />} onPress={() => navigation.navigate('EstadoNutricional')} />
                            <Text textAlign="center">Estado Nutricional</Text>
                        </Center>
                        <Center h="40" w="45%" bg="blueGray.50" rounded="md" borderColor="coolGray.300" borderWidth="1" shadow={3}>
                            <IconButton icon={<Icon as={MaterialIcons} name="assignment" size="2xl" color="black" />} onPress={() => navigation.navigate('Evolucao')} />
                            <Text textAlign="center">Evolução</Text>
                        </Center>
                    </HStack>
                }
            </Center>
        </View>

    );

}